import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uniq from 'lodash/uniq';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import GatewayLink from 'components/GatewayLink';
import { faSquirtyGateway } from 'components/icons';
import Modal from 'components/modals/Modal';
import MultiBatteryPlot from 'components/plots/TransmissionsPlots/MultiBatteryPlot';
import MultiSignalStrengthPlot from 'components/plots/TransmissionsPlots/MultiSignalStrengthPlot';
import MultiSNRPlot from 'components/plots/TransmissionsPlots/MultiSNRPlot';
import MultiSpreadingFactorPlot from 'components/plots/TransmissionsPlots/MultiSpreadingFactorPlot';
import MultiTXPowerPlot from 'components/plots/TransmissionsPlots/MultiTXPowerPlot';
import Sensor from 'utils/types/Sensor';
import Transmission from 'utils/types/Transmission';

export const SensorsHealthIndicatorModalMultipleSensors: React.FC<{
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  sensors: Sensor[];
  transmissions?: Transmission[];
  isPending?: boolean;
}> = ({ show, setShow, sensors, transmissions, isPending }) => {
  const { t } = useTranslation('components');

  // Split up transmissions by Gateway ID
  const gatewayIds = uniq(transmissions?.map(t => t.gateway_id));
  const gatewayIdToTransmissions: { [x: string]: Transmission[] } = {};
  for (const gatewayId of gatewayIds) {
    gatewayIdToTransmissions[gatewayId] = [];
  }
  for (const transmission of transmissions || []) {
    gatewayIdToTransmissions[transmission.gateway_id].push(transmission);
  }

  return (
    <Modal
      title={t('modals.SensorsHealthIndicatorModal.title')}
      show={show}
      setShow={setShow}
      closeOnClickOutside={false}
    >
      {/* Sensor-specific parameters */}
      <div className="mb-3">
        <h3>{t('modals.SensorsHealthIndicatorModal.TXPowerPlot.title')}</h3>
        <MultiTXPowerPlot transmissions={transmissions} sensors={sensors} isPending={isPending} />
      </div>

      <div className="mb-3">
        <h3>{t('modals.SensorsHealthIndicatorModal.SpreadingFactorPlot.title')}</h3>
        <MultiSpreadingFactorPlot
          transmissions={transmissions}
          sensors={sensors}
          isPending={isPending}
        />
      </div>

      <div className="mb-3">
        <h3>{t('modals.SensorsHealthIndicatorModal.MultiBatteryPlot.title')}</h3>
        <MultiBatteryPlot transmissions={transmissions} sensors={sensors} isPending={isPending} />
      </div>

      {/* Sensor-to-gateway-specific parameters */}
      <h2 className="flex justify-between">
        {t('modals.SensorsHealthIndicatorModal.GatewaySpecificParameters.title')}
      </h2>
      <p className="mb-3">
        {t('modals.SensorsHealthIndicatorModal.GatewaySpecificParameters.text')}
      </p>

      {gatewayIds.map(gatewayId => (
        <div key={gatewayId} className="mb-3">
          <h3 className="flex justify-between">
            {t('modals.SensorsHealthIndicatorModal.SignalStrengthPlot.title')}

            <GatewayLink gatewayId={gatewayId}>
              {gatewayId}
              <FontAwesomeIcon className="ml-2" icon={faSquirtyGateway} />
            </GatewayLink>
          </h3>
          <MultiSignalStrengthPlot
            transmissions={gatewayIdToTransmissions[gatewayId]}
            sensors={sensors}
            isPending={isPending}
          />
        </div>
      ))}

      {gatewayIds.map(gatewayId => (
        <div key={gatewayId} className="mb-3">
          <h3 className="flex justify-between">
            {t('modals.SensorsHealthIndicatorModal.SNRPlot.title')}

            <GatewayLink gatewayId={gatewayId}>
              {gatewayId}
              <FontAwesomeIcon className="ml-2" icon={faSquirtyGateway} />
            </GatewayLink>
          </h3>
          <MultiSNRPlot
            transmissions={gatewayIdToTransmissions[gatewayId]}
            sensors={sensors}
            isPending={isPending}
          />
        </div>
      ))}
    </Modal>
  );
};
