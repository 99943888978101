import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFocus } from 'utils/hooks';
import { TailwindHeightString, TailwindWidthString } from 'utils/types';
export const SettingsDropdown: React.FC<
  {
    size?: 'sm' | 'lg';
    containerWidth?: TailwindWidthString;
    containerHeight?: TailwindHeightString;
    pinToTopRight?: boolean;
    onEdit: () => void;
    onDelete?: () => void;
  } & Pick<HTMLAttributes<HTMLDivElement>, 'className'>
> = ({
  size = 'sm',
  containerWidth = 'w-20',
  containerHeight = 'h-20',
  pinToTopRight = true,
  className,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation('components');

  const [dropdownContainerRef, setFocus] = useFocus();
  const [showOptionsDropdown, setShowOptionsDropdown] = useState(false);

  let dropdownTopSpacing = size === 'lg' ? 'top-8' : 'top-7';
  let buttonSize = size === 'lg' ? 'w-7 h-7' : 'w-6 h-6';

  return (
    <div
      tabIndex={0}
      ref={dropdownContainerRef}
      /* Adds a bit of invisible padding to make sure when the user moves towards the open dropdown we don't lose the hover state*/
      className={classNames(
        className,
        'flex items-center justify-center rounded-t focus:outline-none z-30',
        showOptionsDropdown && containerWidth,
        showOptionsDropdown && containerHeight,
        !showOptionsDropdown && buttonSize,
      )}
      onMouseEnter={() => {
        setFocus();
        setShowOptionsDropdown(true);
      }}
      onMouseLeave={() => {
        setShowOptionsDropdown(false);
      }}
      onBlur={() => {
        setShowOptionsDropdown(false);
      }}
    >
      {/* Dropdown settings gear icon*/}
      <div
        className={classNames(
          'flex items-center justify-center',
          'rounded-full inline-block leading-none bg-brand-blue text-xs',
          'transition-translate duration-300',
          pinToTopRight && 'absolute right-0 top-0',
          showOptionsDropdown ? 'hover:rotate-[360deg]' : 'hover:bg-brand-blue-light-1',
          buttonSize,
        )}
        onClick={() => setShowOptionsDropdown(!showOptionsDropdown)}
      >
        <FontAwesomeIcon className="text-white" icon={faGear} size={size} />
      </div>
      {/* Dropdown options */}
      <div
        className={classNames(
          'absolute shadow right-0 flex flex-col text-center overflow-hidden rounded transition-[width, height] duration-300 text-brand-gray py-4',
          dropdownTopSpacing,
          showOptionsDropdown
            ? 'h-[6rem] w-24 bg-white border border-brand-gray'
            : 'h-0 w-0 bg-none',
        )}
      >
        <button
          className="grow w-full m-auto hover:bg-brand-blue hover:text-white"
          onMouseDown={() => {
            // use onMouseDown because onClick won't fire before the parent element loses focus, preventing the modal from showing
            onEdit();
          }}
        >
          {t('dropdowns.SettingsDropdown.edit')}
        </button>
        {onDelete && (
          <button
            className="grow w-full m-auto hover:bg-brand-orange hover:text-white"
            onMouseDown={() => {
              onDelete();
            }}
          >
            {t('dropdowns.SettingsDropdown.delete')}
          </button>
        )}
      </div>
    </div>
  );
};
