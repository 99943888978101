import classNames from 'classnames';
import { format } from 'date-fns';
import React, { forwardRef, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';

type InfiniteScrollProps<T, K extends keyof T> = {
  data: T[];
  isPending: boolean;
  className?: string;
  noMoreDataPlaceholder?: string;
  loadingDataPlaceholder?: string;
  noDataFoundPlaceholder?: string;
  groupBy?: (item: T) => T[K];
  renderItem: (item: T) => ReactNode;
};

export const InfiniteScroll = forwardRef<HTMLDivElement, InfiniteScrollProps<any, any>>(
  <T extends { id: string }, K extends keyof T>(
    {
      data,
      isPending,
      className,
      noMoreDataPlaceholder,
      loadingDataPlaceholder,
      noDataFoundPlaceholder,
      groupBy,
      renderItem,
    }: InfiniteScrollProps<T, K>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const { t } = useTranslation('components');

    const defaultRef = useRef<HTMLDivElement>(null);
    const containerRef = (ref as React.RefObject<HTMLDivElement>) ?? defaultRef;

    const [noMoreData, setNoMoreData] = useState(false);

    const handleScroll = useCallback(() => {
      if (!containerRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight - 10;

      setNoMoreData(isNearBottom);
    }, [containerRef]);

    // Group items by the specified property if groupBy is provided
    const groupedItems = useMemo(
      () =>
        data.reduce(
          (acc, item) => {
            let key: string;
            if (groupBy) {
              const groupValue = groupBy(item);
              if (groupValue instanceof Date) {
                key = format(groupValue, 'dd-MM-yyyy');
              } else {
                key = String(groupValue);
              }
            } else {
              key = 'noGroup';
            }

            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(item);
            return acc;
          },
          {} as Record<string, T[]>,
        ),
      [data, groupBy],
    );

    return (
      <div className="w-full relative">
        <div
          id="infiniteScrollContainer"
          ref={containerRef}
          className={classNames(
            className,
            'space-y-2 overflow-auto w-full z-0 m-2',
            '[&::-webkit-scrollbar]:w-2',
            '[&::-webkit-scrollbar-track]:bg-brand-gray-light-4 [&::-webkit-scrollbar-track]:rounded-full',
            '[&::-webkit-scrollbar-thumb]:bg-brand-gray-light-3 [&::-webkit-scrollbar-thumb]:rounded-full',
          )}
          onScroll={handleScroll}
        >
          {isPending ? (
            <span className="flex items-center justify-center text-brand-gray-light-2 h-full">
              {loadingDataPlaceholder ?? t('InfiniteScroll.loadingData')}
            </span>
          ) : data.length === 0 ? (
            <span className="flex items-center justify-center text-brand-gray-light-2 h-full">
              {noDataFoundPlaceholder ?? t('InfiniteScroll.noDataFound')}
            </span>
          ) : groupBy ? (
            <>
              {Object.keys(groupedItems).map(key => (
                <div key={key}>
                  <div className="flex items-center relative m-2">
                    <div className="flex-1 border-b border-brand-gray-light-3"></div>
                    <span className="mx-2 whitespace-nowrap text-brand-gray-light-2 text-xs">
                      {key}
                    </span>
                    <div className="flex-1 border-b border-brand-gray-light-3"></div>
                  </div>

                  <div className="space-y-2 m-2">
                    {groupedItems[key].map((item, index) => (
                      <LazyLoad key={index} height={100} scrollContainer="#infiniteScrollContainer">
                        {renderItem(item)}
                      </LazyLoad>
                    ))}
                  </div>
                </div>
              ))}
            </>
          ) : (
            // Render flat data if groupBy is not defined
            data.map((item, index) => (
              <LazyLoad key={index} height={100} scrollContainer="#infiniteScrollContainer">
                {renderItem(item)}
              </LazyLoad>
            ))
          )}
        </div>
        {noMoreData && (
          <div className="absolute bottom-0 left-0 right-0 text-sm text-brand-gray-light-2 bg-brand-gray-light-4 bg-opacity-75 text-center py-2 z-10">
            {noMoreDataPlaceholder ?? t('InfiniteScroll.noMoreData')}
          </div>
        )}
      </div>
    );
  },
);
