import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Shape, ShapeConfig } from 'konva/lib/Shape';
import { Vector2d } from 'konva/lib/types';
import queryString from 'query-string';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { RiskScoreExplanation } from 'components/BlueprintCanvas/components/BlueprintMoisturePlot/components';
import MoistureAndPrecipPlot from 'components/plots/CombinedPlots/MoistureAndPrecipPlot';
import { LoadingPlot } from 'components/plots/insights_plots';
import { useSensorTransmissions, useSensorWeatherObservations } from 'utils/hooks/data';
import PlotSession from 'utils/sessions/PlotSession';
import { WeatherPrecipitationDataTuple, WeatherWindDataTuple } from 'utils/types/PlotTypes';

export interface BlueprintMoisturePlotProps {
  blueprintId: string;
  show: boolean;
  position: Vector2d;
  selectedSensorId?: string;
  selectedSensorName?: string;
  timeFrom: Date;
  timeTo: Date;
  canvasSize: { width: number; height: number };
  konvaElement: Shape<ShapeConfig> | undefined;
  onHide: () => void;
  onClearBluePrintPosition: () => void;
}

export const BlueprintMoisturePlot: React.FC<BlueprintMoisturePlotProps> = ({
  blueprintId,
  show,
  position,
  selectedSensorId,
  selectedSensorName,
  timeFrom,
  timeTo,
  canvasSize,
  konvaElement,
  onHide,
  onClearBluePrintPosition,
}) => {
  const { t } = useTranslation('components');

  // Get transmission data
  const { transmissions, emc, referenceValues, isPending } = useSensorTransmissions(
    selectedSensorId!,
    {
      fromTimestamp: timeFrom,
      toTimestamp: timeTo,
      includeMetaColumns: false,
    },
  );

  // Get weather data
  const { sensorWeatherObservations } = useSensorWeatherObservations(selectedSensorId, {
    fromTimestamp: timeFrom,
    toTimestamp: timeTo,
    includeHumidity: true,
    includePrecip: true,
    includeTemperature: true,
    includeWind: true,
  });
  const weatherDataPrecipitation = useMemo(
    () =>
      sensorWeatherObservations?.map(({ timestamp, rain_1h }) => [
        timestamp,
        rain_1h,
      ]) as WeatherPrecipitationDataTuple[],
    [sensorWeatherObservations],
  );
  const weatherDataWind = useMemo(
    () =>
      sensorWeatherObservations?.map(({ timestamp, wind_speed, wind_dir }) => [
        timestamp,
        wind_speed,
        wind_dir,
      ]) as WeatherWindDataTuple[],
    [sensorWeatherObservations],
  );

  // Handle plot visibility
  const [showPlot, setShowPlot] = useState(show);

  useEffect(() => {
    setShowPlot(show);
  }, [show]);

  const handleMouseLeave = () => {
    setShowPlot(false);
    onHide();
    onClearBluePrintPosition();
  };

  const handleMouseEnter = () => {
    setShowPlot(true);
  };

  // Handle plot position:
  //  If the cursor is above the canvas center, show the plot below the cursor
  //    Else show above
  //  If the cursor is to the left of the canvas center, show the plot to the right of the cursor
  //    Else show to the left
  const putPlotRightOfCursor = position.x < canvasSize.width * 0.5;
  const putPlotAboveCursor = position.y > canvasSize.height * 0.5;

  // Handle click events
  const handleMouseClick = () => {
    // Because we're using a div on top of the canvas as padding around the icon, we need to propagate click events to the canvas
    if (!konvaElement) return;
    konvaElement.fire('dragstart');
    konvaElement.fire('click');
  };

  const handleMouseClickGraph = (e: MouseEvent<HTMLDivElement>) => {
    // We don't however want to propagate a click on the plot to the canvas, since it would open the sensor modal on top of the plot
    e.stopPropagation();
  };

  const linkToSensorPage = {
    pathname: `/user/sensors/${selectedSensorId}`,
    search: queryString.stringify({
      timeFrom: timeFrom?.toISOString(),
      timeTo: timeTo?.toISOString(),
    }),
  };

  return (
    // The outer div here gives some padding around the sensor icon, so the user has a larger area to hover over and can go from the sensor icon
    // to the plot without the plot disappearing, while keeping the label of the sensor icon visible.
    <div
      id="blueprint-moisture-plot"
      className={classNames('absolute translate-x-1/2 translate-y-1/2 z-40', {
        'w-20 h-20': show,
        'w-0 h-0': !show,
      })}
      style={{ top: position.y, left: position.x }}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      onClick={handleMouseClick}
    >
      {showPlot && (
        <div
          className={classNames(
            'absolute bg-white rounded-md border-2 border-brand-gray-light-1',
            'w-[40rem] h-fit p-4',
            {
              'left-12': putPlotRightOfCursor,
              'right-12': !putPlotRightOfCursor,
              'top-6': !putPlotAboveCursor,
              'bottom-6': putPlotAboveCursor,
            },
          )}
          onClick={handleMouseClickGraph}
        >
          <div className="pb-2">
            <Link
              target="_blank"
              rel="noreferrer noopener"
              to={linkToSensorPage}
            >{`${t('blueprints.CanvasMoisturePlot.title')} ${selectedSensorName}`}</Link>
          </div>
          {isPending && <LoadingPlot height="h-80" backgroundColor="bg-white" />}
          {!isPending && (
            <PlotSession timePeriod={[timeFrom, timeTo]}>
              <MoistureAndPrecipPlot
                emc={emc}
                referenceValues={referenceValues}
                transmissions={transmissions}
                transmissionForecasts={[]} // forecasts seem to be a bit wonky for some reason so ignoring for now
                weatherDataPrecip={weatherDataPrecipitation}
                weatherDataWind={weatherDataWind}
                showToolbar={false}
                showAnimation={false}
                enableZoom={false}
                fixMoistureYAxis={true}
                rotateXAxisLabelsForLargeDateRange={true}
                sensorId={selectedSensorId}
                sensorName={selectedSensorName}
              />
              <div className="flex justify-between">
                <RiskScoreExplanation
                  blueprintId={blueprintId}
                  selectedSensorId={selectedSensorId}
                  timeFrom={timeFrom}
                  timeTo={timeTo}
                />

                <Link
                  className="flex items-center text-xs text-brand-gray-light-2 hover:text-brand-gray"
                  target="_blank"
                  rel="noreferrer noopener"
                  to={linkToSensorPage}
                >
                  {t('blueprints.CanvasMoisturePlot.openSensorPageInNewTab')}
                  <FontAwesomeIcon className="mx-1" icon={faArrowRight} size="sm" />
                </Link>
              </div>
            </PlotSession>
          )}
        </div>
      )}
    </div>
  );
};
