import classnames from 'classnames';
import { Formik, FormikHelpers } from 'formik';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  DatePickerInput,
  FormCheckboxInput,
  FormTextInput,
  SensorSelectInput,
  SensorsSelectInput,
  StaticTextInput,
  SubmittingButton,
} from 'components';
import ErrorMessageDisplay from 'components/forms/helpers/ErrorMessageDisplay';
import InfoText from 'components/InfoText';
import { parseI18LanguageToLocale } from 'utils/enums/Locale';
import {
  useCustomerSensors,
  useReportRevisionSensors,
  useUser,
  useUserSensors,
} from 'utils/hooks/data';
import { ReportRevisionFormValues } from 'utils/types/ReportRevision';
import Sensor from 'utils/types/Sensor';

export interface ReportRevisionFormProps {
  onSubmit?: (
    values: ReportRevisionFormValues,
    { resetForm }: FormikHelpers<ReportRevisionFormValues>,
  ) => Promise<void>;
  values?: Partial<Omit<ReportRevisionFormValues, 'customer_id'>>;
  onDelete?: (values: ReportRevisionFormValues) => Promise<void>;
  buttonText?: string;
  submittingText?: string;
  includeName?: boolean;
  lockSensors?: boolean;
  hideSubmitButton?: boolean;
  userId: string | undefined;
  customerId: string | undefined;
  reportId?: string;
  reportRevisionId?: string;
  isPending?: boolean;
}

const ReportRevisionForm: React.FC<ReportRevisionFormProps> = ({
  onSubmit,
  values,
  onDelete,
  buttonText,
  submittingText,
  includeName = false,
  lockSensors = false,
  hideSubmitButton = false,
  userId,
  customerId,
  reportId,
  reportRevisionId,
  isPending: _isPending,
}) => {
  const [initialSelectedSensorIds, setInitialSelectedSensorIds] = useState<string[]>(
    values?.sensor_ids || [],
  );

  const [openDatePickerCalendar, setOpenDatePickerCalendar] = useState(false);

  const { user } = useUser(values?.created_by);
  const { sensors: sensorsReportRevision, isPending: isPendingRevisionSensors } =
    useReportRevisionSensors(reportId, reportRevisionId);
  const { sensors: sensorsCustomer, isPending: isPendingCustomerSensors } =
    useCustomerSensors(customerId);
  const { sensors: sensorsUser, isPending: isPendingUserSensors } = useUserSensors(userId);

  const isPending =
    _isPending || isPendingRevisionSensors || isPendingCustomerSensors || isPendingUserSensors;

  const sensors = uniqBy(
    [...(sensorsUser || []), ...(sensorsCustomer || []), ...(sensorsReportRevision || [])],
    sensor => sensor.id,
  );

  const readOnly = !onSubmit;

  const { t, i18n } = useTranslation('components');

  const schema = yup.object({
    description: yup.string().nullable(),
    locale: yup.string().nullable(),
    customer_id: yup.string().nullable(),
    sensor_ids: yup
      .array()
      .of(yup.string())
      .min(1, t('forms.ReportRevisionForm.schema.sensor_ids.min'))
      .default([]),
    timestamp_from: yup
      .date()
      .required(t('forms.ReportRevisionForm.schema.timestamp_from.required')),
    timestamp_to: yup.date().required(t('forms.ReportRevisionForm.schema.timestamp_to.required')),
    include_weather: yup.boolean().default(false).nullable(),
    include_notes: yup.boolean().default(true).nullable(),
    include_images: yup.boolean().default(false).nullable(),
    include_blueprints: yup.boolean().default(false).nullable(),
    use_custom_location: yup.boolean().default(false).nullable(),
    sensor_id_weather_location: yup.string().nullable(),
    // LEGACY
    address: yup.string().nullable(),
    // LEGACY
    ...(includeName
      ? {
          name: yup.string().required(t('forms.ReportForm.schema.name.required')), // @ts-ignore
        }
      : {}),
  });

  const initialValues = schema.cast(
    { ...values, customer_id: customerId },
    {
      assert: false,
      stripUnknown: true,
    },
  ) as ReportRevisionFormValues;
  initialValues.locale = parseI18LanguageToLocale(i18n.language);

  const formikSubmit = onSubmit ? onSubmit : async () => {};

  useEffect(() => {
    // Define initial selected sensors if any provided
    if (values?.sensor_ids && !isEqual(values?.sensor_ids, initialSelectedSensorIds)) {
      setInitialSelectedSensorIds(values?.sensor_ids);
    }
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      onSubmit={formikSubmit}
      initialValues={initialValues}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ dirty, isValid, isSubmitting, ...props }) => {
        const onDateRangeSelect = ({ start, end }: { start: Date; end: Date }) => {
          const updatedValues = Object.assign(props.values, {
            timestamp_from: start,
            timestamp_to: end,
          });
          props.setValues(updatedValues, true);
        };

        const onSensorSelectBlur = async (sensorIds: string[]) => {
          props.setFieldTouched('sensor_ids', true);
          props.setFieldValue('sensor_ids', sensorIds, true);
          props.setFieldValue('sensor_id_weather_location', sensorIds[0], true);
        };

        const sensorIds = props.values.sensor_ids as string[];

        const sensorsSelected = sensors?.filter(sensor => sensorIds.find(id => sensor.id === id));

        const sensorWeatherLocation = sensorsSelected?.find(
          sensor => props.values.sensor_id_weather_location === sensor?.id,
        );

        return (
          <form id="reportRevisionForm" noValidate onSubmit={props.handleSubmit}>
            {includeName && (
              <FormTextInput
                label={t('forms.ReportForm.name.label')}
                name="name"
                placeholder={t('forms.ReportForm.name.placeholder')}
                readOnly={readOnly}
              />
            )}

            <FormTextInput
              label={t('forms.ReportRevisionForm.description.label')}
              name="description"
              placeholder={t('forms.ReportRevisionForm.description.placeholder')}
              readOnly={readOnly}
            />

            <div className="mb-3">
              {onSubmit && <label>{t('forms.ReportRevisionForm.dateRange.label')}</label>}
              {!onSubmit && <label>{t('forms.ReportRevisionForm.dateRange.label2')}</label>}

              <DatePickerInput
                key={`${props.values.timestamp_from}-${props.values.timestamp_to}`}
                onSelect={({ start, end }) => {
                  if (start && end) onDateRangeSelect({ start, end });
                }}
                initialStartDate={props.values.timestamp_from}
                initialEndDate={props.values.timestamp_to}
                showPredefinedRanges={true}
                maxMonthsBack={48}
                openDatePickerCalendar={openDatePickerCalendar}
                setOpenDatePickerCalendar={setOpenDatePickerCalendar}
                maxDate={new Date()}
                isDisabled={readOnly}
              />
            </div>

            {!onSubmit && <StaticTextInput label="Created by" value={user?.full_name} />}

            <div className="mb-3">
              {lockSensors && (
                <InfoText
                  className="my-3"
                  text={t('cards.forms.ReportRevisionForm.lockSensors.infoText')}
                />
              )}
              <SensorsSelectInput
                label={t('forms.ReportRevisionForm.sensors.label')}
                onBlur={onSensorSelectBlur}
                initialSelectedSensorIds={initialSelectedSensorIds}
                disabled={lockSensors || readOnly || isPending}
                sensorsToSelect={sensors}
                isPending={isPending}
              />
              {props.errors.sensor_ids && props.touched.sensor_ids && (
                <p className="text-brand-orange">{props.errors.sensor_ids}</p>
              )}

              {/* Only show "Select all" button if there are less than 100 sensors */}
              {!readOnly && sensors && sensors.length < 100 && (
                <button
                  className="mt-1 text-xs lg:text-base text-brand-gray-light-2 hover:text-blue-500 hover:underline"
                  onClick={e => {
                    e.preventDefault();
                    const sensorIds = (sensors || [])?.map(sensor => sensor.id);
                    setInitialSelectedSensorIds(sensorIds);
                    onSensorSelectBlur(sensorIds);
                  }}
                >
                  {t('forms.ReportRevisionForm.sensors.selectAll')}
                </button>
              )}
            </div>

            <FormCheckboxInput
              label={t('forms.ReportRevisionForm.weather.label')}
              name="include_weather"
              disabled={readOnly}
            />

            {props.values.include_weather && (
              <>
                <p className="mb-3">
                  <>
                    {sensorWeatherLocation &&
                      t('forms.ReportRevisionForm.use_custom_location.text', {
                        sensorName: sensorWeatherLocation?.name,
                        sensorId: sensorWeatherLocation?.hardware_id,
                      })}
                    {!sensorWeatherLocation &&
                      t('forms.ReportRevisionForm.use_custom_location.textLegacy')}
                  </>
                </p>
                <FormCheckboxInput
                  label={t('forms.ReportRevisionForm.use_custom_location.label')}
                  name="use_custom_location"
                  disabled={readOnly}
                />
              </>
            )}

            {props.values.include_weather && props.values.use_custom_location && (
              <div className="mb-3">
                <SensorSelectInput
                  initialSelectedSensorId={props.values.sensor_id_weather_location}
                  sensors={sensorsSelected}
                  onSelect={(sensor?: Sensor) =>
                    props.setFieldValue('sensor_id_weather_location', sensor?.id)
                  }
                  disabled={lockSensors || readOnly}
                />
              </div>
            )}

            <FormCheckboxInput
              label={t('forms.ReportRevisionForm.notes.label')}
              name="include_notes"
              disabled={readOnly}
            />

            <FormCheckboxInput
              label={t('forms.ReportRevisionForm.images.label')}
              name="include_images"
              disabled={readOnly}
            />

            <FormCheckboxInput
              label={t('forms.ReportRevisionForm.blueprints.label')}
              name="include_blueprints"
              disabled={readOnly}
            />

            {onSubmit && !hideSubmitButton && (
              <SubmittingButton
                buttonText={buttonText || t('components:forms.ReportRevisionForm.buttonText')}
                submittingText={
                  submittingText || t('components:forms.ReportRevisionForm.submittingText')
                }
                submitting={isSubmitting}
              />
            )}

            {onDelete && (
              <SubmittingButton
                className={classnames('mt-2', {
                  'ml-3': onSubmit,
                })}
                variant="outline-danger"
                buttonText={t('forms.ReportRevisionForm.deleteButton.buttonText')}
                submittingText={t('forms.ReportRevisionForm.deleteButton.submittingText')}
                submitting={isSubmitting}
                onClick={() => onDelete(props.values)}
              />
            )}

            {!isValid && <ErrorMessageDisplay />}
          </form>
        );
      }}
    </Formik>
  );
};

export default ReportRevisionForm;
