import MultiTransmissionsPlot, {
  MultiTransmissionsPlotProps,
} from 'components/plots/TransmissionsPlots/MultiTransmissionsPlot';

type InputProps = Omit<MultiTransmissionsPlotProps, 'dataField' | 'formatter'>;

const MultiBatteryPlot: React.FC<InputProps> = ({ ...props }) => (
  <MultiTransmissionsPlot dataField="battery" formatter="{value}" {...props} />
);

export default MultiBatteryPlot;
