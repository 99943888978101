export const LABEL_CONFIG = {
  headerFontSize: 12,
  bodyFontSize: 10,
  fontFamily: 'montserrat',
  textColor: 'white',
  textAlign: 'left',
  wrap: 'none',
  elipsis: true,
  textToLabelWidthRatio: 0.7,
  labelWidthToPinWidthRatio: 2.1,
  labelHeightToPinHeightRatio: 0.7,
  backgroundRectYOffset: 1.05,
};
