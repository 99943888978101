import { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueprintCanvasContext, Canvas } from 'components/BlueprintCanvas/components';
import { BlueprintPosition } from 'utils/types';

export const BlueprintCanvas: React.FC<
  {
    blueprintId: string;
    stageHeight?: number;
    onlyEnableSensorIds?: string[];
    onlyShowSensorIds?: string[];
    onlyShowTooltipforSensorIds?: string[];
    showSensors?: boolean;
    showHeader?: boolean;
    showPlayableTimeline?: boolean;
    enableWheelScrolling?: boolean;
    enableDragging?: boolean;
    showToolbox?: boolean;
    playTimelapseOnMount?: boolean;
    loopTimelapse?: boolean;
    editModeEnabled?: boolean;
    setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  } & HTMLAttributes<HTMLDivElement>
> = ({
  blueprintId,
  stageHeight,
  onlyEnableSensorIds,
  onlyShowSensorIds,
  onlyShowTooltipforSensorIds,
  showHeader = false,
  showSensors = false,
  showPlayableTimeline = false,
  enableWheelScrolling = false,
  enableDragging = false,
  showToolbox = false,
  playTimelapseOnMount = false,
  loopTimelapse = false,
  className = 'shadow mb-3',
  editModeEnabled = false,
  setEditMode = () => {},
  ...props
}) => {
  const { t } = useTranslation('components');
  // States
  const [hours, setHours] = useState(0);
  const [lockSensors, setLockSensors] = useState(true);
  const [activeBlueprintPosition, setActiveBlueprintPosition] = useState<BlueprintPosition>();
  const [hiddenBlueprintPositionIds, setHiddenBlueprintPositionsIds] = useState<string[]>([]);
  const [modifyingAttachedSensors, setModifyingAttachedSensors] = useState(false);
  const [blueprintHeight, setBlueprintHeight] = useState(0); // This gets calculated in the Canvas component
  const [editModeTooltip, setEditModeTooltip] = useState(
    t('blueprints.Sidebar.tooltips.positionSensors'),
  );

  return (
    <BlueprintCanvasContext.Provider
      value={{
        hours,
        setHours,
        lockSensors,
        setLockSensors,
        activeBlueprintPosition,
        setActiveBlueprintPosition,
        editModeEnabled,
        setEditMode,
        hiddenBlueprintPositionIds,
        setHiddenBlueprintPositionsIds,
        modifyingAttachedSensors,
        setModifyingAttachedSensors,
        blueprintId,
        blueprintHeight,
        setBlueprintHeight,
        editModeTooltip,
        setEditModeTooltip,
      }}
    >
      <div className={className} {...props}>
        <Canvas
          blueprintId={blueprintId}
          stageHeightPercentage={stageHeight}
          onlyEnableSensorIds={onlyEnableSensorIds}
          onlyShowSensorIds={onlyShowSensorIds}
          onlyShowTooltipforSensorIds={onlyShowTooltipforSensorIds}
          enableWheelScrolling={enableWheelScrolling}
          enableDragging={enableDragging}
          enableToolbox={showToolbox}
        />
      </div>
    </BlueprintCanvasContext.Provider>
  );
};
