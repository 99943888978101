import debounce from 'lodash/debounce';
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from 'react';

const DEFAULT_TAG_OPTIONS = new Set<string>([
  'External Walls',
  'Interior Walls',
  'Load-bearing beams and columns',
  'Roofs',
  'Slabs',
]);

export const TagsSelect: React.FC<{
  tagOptions: Set<string>;
  setSelectedTags: Dispatch<SetStateAction<Set<string>>>;
}> = ({ tagOptions, setSelectedTags }) => {
  if (tagOptions.size === 0) tagOptions = DEFAULT_TAG_OPTIONS; // Provide some basic tags if needed
  const [selectedTagsInComponent, setSelectedTagsInComponent] = useState<Set<string>>(
    new Set<string>([...tagOptions]),
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSelectedTagsDebounced = useCallback(
    debounce(tags => setSelectedTags(tags), 1500),
    [],
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const tagType = e.target.id;
    const tags = new Set(selectedTagsInComponent);
    tags.has(tagType) ? tags.delete(tagType) : tags.add(tagType);

    setSelectedTagsInComponent(tags);
    setSelectedTagsDebounced(tags);
  };

  return (
    <div className="flex gap-4 my-2">
      {[...tagOptions].map((tagType, i) => (
        <label key={i}>
          {tagType}
          <input
            id={tagType}
            className="mx-2"
            type="checkbox"
            defaultChecked={true}
            onChange={onChange}
          />
        </label>
      ))}
    </div>
  );
};
