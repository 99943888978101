import orderBy from 'lodash/orderBy';

import { HeaderAlertRuleColumn } from 'components/SensorsAlertRulesMatrix/components';
import AlarmRule from 'utils/types/AlarmRule';

export const HeaderAlertRulesRow: React.FC<{
  alertRules?: AlarmRule[];
}> = ({ alertRules }) => (
  <div className="flex justify-normal ml-32">
    {orderBy(alertRules, ['type', 'subtype']).map((alertRule, index) => (
      <HeaderAlertRuleColumn key={alertRule.id} columnIndex={index} alertRule={alertRule} />
    ))}
  </div>
);
