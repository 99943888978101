const LOGO_SVG_BASE_SIZE = { width: 1466.7, height: 1133.3 };

export const PIN_CONFIG = {
  sensorPinActiveScale: 1.25,
  pinStrokeWidth: 0.075,
  pinStrokeColor: 'white',
  logoWidthToPinWidthRatio: 0.65,
  logoSvgBaseSize: LOGO_SVG_BASE_SIZE,
  logoWidthToHeightRatio: LOGO_SVG_BASE_SIZE.height / LOGO_SVG_BASE_SIZE.width,
  logoXOffset: 0.55,
  logoYOffset: 0.05,
};
