import Locale, { parseI18LanguageToLocale } from 'utils/enums/Locale';

interface User {
  id: string;

  customer_id?: string;
  demo_application_id?: string;
  email: string;
  full_name?: string;
  hashed_password?: string;
  is_admin?: boolean;
  last_locale_used?: Locale;
  last_signed_in_at?: Date;
  password_salt?: string;
  phone_number?: string;
  receive_alarms_email?: boolean;
  receive_alarms_sms?: boolean;

  created_date: Date;
  created_by?: string;
  updated_date?: Date;
  updated_by?: string;
}

export const parseUser = (user: any): User => {
  user.created_date = new Date(user.created_date);
  if (user.updated_date) user.updated_date = new Date(user.updated_date);
  if (user.last_signed_in_at) user.last_signed_in_at = new Date(user.last_signed_in_at);
  if (user.last_locale_used)
    user.last_locale_used = parseI18LanguageToLocale(user.last_locale_used);

  return user as User;
};

export default User;
