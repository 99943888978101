import { ToolboxComponentOption } from 'echarts/components';
import React, { Dispatch, SetStateAction } from 'react';

import AnomalyValidationType from 'utils/enums/AnomalyValidationType';
import TimePeriod from 'utils/types/TimePeriod';

export type ContextProps = {
  enableAnomalies?: boolean;
  enableForecasts?: boolean;
  filterAnomalyValidationType?: AnomalyValidationType;
  onMarkAnomaliesClick?: (timePeriod: TimePeriod) => void;
  onTimePeriodSelectClick?: () => void;
  onTransmissionClick?: (transmissionId: string) => void;
  setFilterAnomalyValidationType: React.Dispatch<
    React.SetStateAction<AnomalyValidationType | undefined>
  >;
  setShowAnomalies: (showAnomalies: boolean) => void;
  setShowAnomalyFilteringModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowForecasts: Dispatch<SetStateAction<boolean>>;
  setShowGatewayHealth: Dispatch<SetStateAction<boolean>>;
  setShowGateways: Dispatch<SetStateAction<boolean>>;
  setShowSignalStrength: Dispatch<SetStateAction<boolean>>;
  showAnomalies: boolean;
  showForecasts: boolean;
  showGatewayHealth: boolean;
  showGateways: boolean;
  showSignalStrength: boolean;
  timePeriod: Required<TimePeriod>;
  highlightPeriod?: TimePeriod;
  createToolboxSettings: (props: CreateToolboxSettingsProps) => ToolboxComponentOption;
};

export type CreateToolboxSettingsProps = {
  hardwareId: string;
  saveAsImageFilename: string;
  onTimePeriodResetClick?: () => void;
  showBrush?: boolean;
  showZoom?: boolean;
};

const PlotContext = React.createContext<ContextProps>({} as ContextProps);

export default PlotContext;
