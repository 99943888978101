import uniqBy from 'lodash/uniqBy';
import { useMemo } from 'react';

import { useCustomerGateways, useUserGateways } from 'utils/hooks/data';
import { allCustomer } from 'utils/types/Customer';
import HookOptions from 'utils/types/HookOptions';

type Options = HookOptions & {
  includeGeographicLocation?: boolean;
};

export const useUserAndCustomerGateways = (
  userId?: string,
  customerId?: string,
  { enableGet = true, includeGeographicLocation = true }: Options = {},
) => {
  const { gateways: gatewaysUser, isPending: isPendingUserGateways } = useUserGateways(userId, {
    enableGet,
    includeGeographicLocation,
  });
  const { gateways: gatewaysCustomer, isPending: isPendingCustomerGateways } = useCustomerGateways(
    customerId,
    { enableGet, includeGeographicLocation },
  );

  const gateways = useMemo(() => {
    const gateways = [...(gatewaysUser || []), ...(gatewaysCustomer || [])];
    const gatewaysFiltered =
      customerId === allCustomer.id
        ? gateways?.filter(
            gateway => gateway.customer_id !== '615a9de03b775002ce06178f', // WOODSENSE_GRAVEYARD
          )
        : gateways;

    return uniqBy(gatewaysFiltered, 'id');
  }, [gatewaysUser, gatewaysCustomer, customerId]);

  return {
    gateways,
    isPending: isPendingUserGateways || isPendingCustomerGateways,
  };
};
