import { faExclamationCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueprintCanvasContext } from 'components/BlueprintCanvas/components/BlueprintCanvasContext';
import { useBlueprintSensors } from 'utils/hooks/data';

export const SidebarAddSensorRow: React.FC<{
  sensorName: string;
  sensorId: string;
  blueprintId: string;
  disabled: boolean;
  tooltip?: string;
}> = ({ sensorName, sensorId, blueprintId, disabled, tooltip }) => {
  // Context
  const { setModifyingAttachedSensors } = useContext(BlueprintCanvasContext);

  // Hooks
  const { t } = useTranslation('components');
  const { addSensorToBlueprintById, addSensorToBlueprintPending } =
    useBlueprintSensors(blueprintId);

  return (
    <div
      className={classNames(
        'flex w-full py-0.5 px-2 border-b cursor-pointer max-h-[24px] min-h-[24px]',
        disabled
          ? 'bg-brand-beige-light-1 hover:bg-brand-beige-light-2 border-brand-beige-light-2'
          : 'hover:bg-brand-lime-light-3',
      )}
      data-tooltip-content={tooltip || t('blueprints.Sidebar.dataTip.addSensor')}
      data-tooltip-id="route-tooltip"
    >
      <div className="text-ellipsis text-nowrap overflow-hidden">{sensorName}</div>
      <div
        className="flex grow justify-end items-center mr-4"
        onClick={() => {
          if (!disabled && !addSensorToBlueprintPending) {
            setModifyingAttachedSensors(true);
            addSensorToBlueprintById(sensorId).then(() => setModifyingAttachedSensors(false));
          }
        }}
      >
        {!disabled && <FontAwesomeIcon className="w-3.5 h-3.5" icon={faPlusCircle} />}
        {disabled && (
          <FontAwesomeIcon
            className="w-3.5 h-3.5"
            icon={faExclamationCircle}
            data-tooltip-content={tooltip}
          />
        )}
      </div>
    </div>
  );
};
