import classNames from 'classnames';
import { forwardRef, InputHTMLAttributes, MutableRefObject, ReactNode, useRef } from 'react';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  label?: string | ReactNode;
  labelClassName?: string;
  checked?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ name, label, id, labelClassName, checked, className, ...props }, ref) => (
    <div className={classNames({ 'flex gap-x-2 items-center': !!label })}>
      {id ? (
        <CheckboxWithId
          ref={ref}
          id={id}
          name={name}
          label={label}
          labelClassName={labelClassName}
          checked={checked}
          className={className}
          {...props}
        />
      ) : (
        <CheckboxWithoutId
          ref={ref}
          name={name}
          label={label}
          labelClassName={labelClassName}
          checked={checked}
          className={className}
          {...props}
        />
      )}
    </div>
  ),
);

const CheckboxWithId = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ name, label, id, labelClassName, checked, className, required, ...props }, ref) => (
    <>
      <input
        ref={ref}
        checked={checked}
        name={name}
        id={id}
        type="checkbox"
        className={classNames(
          className,
          'text-brand-green form-checkbox w-4 h-4 border focus:ring-transparent focus:outline outline-blue-500 focus:ring-opacity-25 rounded ease-in-out duration-150 transition-all',
          {
            'opacity-50 cursor-not-allowed': props.disabled,
            'cursor-pointer': !props.disabled,
          },
        )}
        {...props}
      />
      {label && (
        <label className={classNames(labelClassName)} htmlFor={id}>
          {label}
          {required && (
            <span
              className="inline"
              data-tooltip-content="This is a required field"
              data-tooltip-id="route-tooltip"
            >
              *
            </span>
          )}
        </label>
      )}
    </>
  ),
);

const CheckboxWithoutId = forwardRef<HTMLInputElement, Omit<CheckboxProps, 'id'>>(
  ({ name, label, labelClassName, checked, className, required, ...props }, ref) => {
    const refInner = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement> | undefined;
    return (
      <>
        <input
          ref={el => {
            if (refInner) refInner.current = el!;
            if (ref) (ref as MutableRefObject<HTMLInputElement>).current = el!;
          }}
          checked={checked}
          name={name}
          type="checkbox"
          className={classNames(
            className,
            'text-brand-green form-checkbox w-4 h-4 border focus:ring-transparent focus:outline outline-blue-500 focus:ring-opacity-25 rounded ease-in-out duration-150 transition-all',
            {
              'opacity-50 cursor-not-allowed': props.disabled,
              'cursor-pointer': !props.disabled,
            },
          )}
          {...props}
        />
        {label && (
          <label
            className={classNames(labelClassName)}
            onClick={e => {
              // NOTE: this approach is used in order to support duplicated Checkbox
              // elements not referencing the same input element through the "for" attribute.
              e.preventDefault();
              refInner?.current.click();
            }}
          >
            {label}
            {required && (
              <span
                className="inline"
                data-tooltip-content="This is a required field"
                data-tooltip-id="route-tooltip"
              >
                *
              </span>
            )}
          </label>
        )}
      </>
    );
  },
);

export default Checkbox;
