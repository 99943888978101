import classNames from 'classnames';
import { useContext } from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { MeasuredCellParent } from 'react-virtualized/dist/es/CellMeasurer';
import { GridCoreProps } from 'react-virtualized/dist/es/Grid';

import { BlueprintCanvasContext } from 'components/BlueprintCanvas/components/BlueprintCanvasContext';
import { SidebarAddSensorRow } from 'components/BlueprintCanvas/components/Sidebar/SidebarAddSensors/components/SidebarAddSensorRow';
import Sensor from 'utils/types/Sensor';

interface SensorsListProps {
  sensorsByOrganisation: Map<string, Sensor[]>;
  disabledSensorIds: string[];
  sensorIdsToTooltips: Map<string, string>;
}

export const SensorsLists: React.FC<SensorsListProps> = ({
  sensorsByOrganisation,
  disabledSensorIds,
  sensorIdsToTooltips,
}) => {
  const { blueprintId } = useContext(BlueprintCanvasContext);

  let organisationIndices: number[] = [];
  let rowList: (Sensor | string)[] = [];
  for (let [organisationName, sensors] of sensorsByOrganisation) {
    rowList.push(organisationName);
    organisationIndices.push(rowList.length - 1);

    rowList.push(...sensors);
  }
  const rowHeight = 24;

  const cellMeasurerCache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: rowHeight,
  });

  const renderRow = ({
    index,
    key,
    style,
    parent,
  }: {
    index: number;
    key: string;
    style: React.CSSProperties;
    isScrolling: boolean;
    parent: React.Component<GridCoreProps> & MeasuredCellParent;
  }): React.ReactNode => {
    const isOrganisationRow = typeof rowList[index] === 'string';

    return (
      <CellMeasurer
        key={key}
        cache={cellMeasurerCache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <div
            ref={registerChild}
            className={classNames({
              'px-2 py-1 text-sm border-b border-brand-green-light-4': isOrganisationRow,
              'h-[24px]': !isOrganisationRow,
            })}
            style={style}
          >
            {isOrganisationRow && (rowList[index] as string)}
            {!isOrganisationRow && (
              <SidebarAddSensorRow
                key={(rowList[index] as Sensor).id}
                sensorName={(rowList[index] as Sensor).name}
                sensorId={(rowList[index] as Sensor).id}
                blueprintId={blueprintId}
                disabled={disabledSensorIds.includes((rowList[index] as Sensor).id)}
                tooltip={sensorIdsToTooltips.get((rowList[index] as Sensor).id) || undefined}
              />
            )}
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <AutoSizer>
      {({ width, height }) => (
        <List
          width={width}
          height={height}
          deferredMeasurementCache={cellMeasurerCache}
          rowHeight={cellMeasurerCache.rowHeight}
          rowRenderer={renderRow}
          rowCount={rowList.length}
          estimatedRowSize={rowList.length * rowHeight}
          overscanRowCount={10}
        />
      )}
    </AutoSizer>
  );
};
