import MultiTransmissionsPlot, {
  MultiTransmissionsPlotProps,
} from 'components/plots/TransmissionsPlots/MultiTransmissionsPlot';

type InputProps = Omit<MultiTransmissionsPlotProps, 'dataField' | 'formatter'>;

const MultiSignalStrengthPlot: React.FC<InputProps> = ({ ...props }) => (
  <MultiTransmissionsPlot dataField="snr" formatter="{value}" {...props} />
);

export default MultiSignalStrengthPlot;
