import { useState } from 'react';

import { BlueprintCanvas } from 'components/BlueprintCanvas';

export const BlueprintSensorPositioning: React.FC<{
  blueprintId: string;
  onlyEnableSensorIds?: string[];
}> = ({ blueprintId, onlyEnableSensorIds }) => {
  // We need to define this here until we bring the sensor carousel behaviour to the canvas sidebar,
  // see https://linear.app/woodsense/issue/SW-732/sidebar-edit-mode
  const [editModeEnabled, setEditMode] = useState(false);

  return (
    <div>
      <BlueprintCanvas
        blueprintId={blueprintId}
        onlyEnableSensorIds={onlyEnableSensorIds}
        showHeader
        showSensors
        showPlayableTimeline
        showToolbox
        enableWheelScrolling
        enableDragging
        editModeEnabled={editModeEnabled}
        setEditMode={setEditMode}
      />
    </div>
  );
};
