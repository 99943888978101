import {
  faCalendar,
  faExpandArrowsAlt,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faPalette,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Konva from 'konva';
import 'konva/lib/shapes/Image';
import 'konva/lib/shapes/Label';
import 'konva/lib/shapes/Text';
import React, { Dispatch, MutableRefObject, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePickerInputModal } from 'components';
import { MAX_SCALE, MIN_SCALE } from 'components/CanvasStage';
import { ImageSize, StageState } from 'components/CanvasStage/types';
import environment from 'utils/environment';
import { useTimePeriod } from 'utils/hooks';
import { isFeatureEnabled } from 'utils/isFeatureEnabled';
import { truncateNumber } from 'utils/numbers';

export const CanvasToolboxes: React.FC<{
  stageRef?: MutableRefObject<Konva.Stage>;
  setStageState: Dispatch<SetStateAction<StageState>>;
  grayscale?: boolean;
  setGrayscale: Dispatch<SetStateAction<boolean>>;
  imageSize: ImageSize;
  topButtons?: JSX.Element;
  bottomButtons?: JSX.Element;
}> = ({
  stageRef,
  setStageState,
  grayscale,
  setGrayscale,
  imageSize,
  topButtons,
  bottomButtons,
}) => {
  const { t } = useTranslation('components');

  const interval = useRef<NodeJS.Timeout | null>(null);

  const [showCalendarModal, setShowCalendarModal] = useState(false);

  // Hooks
  const {
    timePeriod: [timeFrom, timeTo],
    setTimePeriod,
  } = useTimePeriod();

  const onDateRangeSelect = ({ start, end }: { start: Date; end: Date }) => {
    setShowCalendarModal(false);
    setTimePeriod([start, end]);
  };

  // Set initial position to be centered and maxed zoomed out
  const resetStageState = () => {
    if (!stageRef?.current) return;

    const stageWidth = stageRef.current.width();
    const stageHeight = stageRef.current.height();

    // Calculate scale that matches the best fit for one of the sides
    const scaleWidth = stageWidth / imageSize.width;
    const scaleHeight = stageHeight / imageSize.height;
    const scaleMin = Math.min(scaleWidth, scaleHeight);
    const scale = truncateNumber(scaleMin, MIN_SCALE, MAX_SCALE);

    setStageState({
      scale,
      x: -(imageSize.width * scale - stageWidth) / 2.0,
      y: -(imageSize.height * scale - stageHeight) / 2.0,
    });
  };

  const zoomContinuously = (scale: number) => {
    const timer = setInterval(
      () =>
        setStageState(state => ({
          scale: state.scale * scale,
          x: state.x,
          y: state.y,
        })),
      50,
    );
    interval.current = timer;
  };

  const isToggleBlueprintColorEnabled = isFeatureEnabled('toggleBlueprintColor', environment);

  return (
    <div className="shadow rounded-sm flex lg:flex-col lg:items-center p-0.5 lg:p-2 gap-2 border border-brand-gray-light-4">
      {topButtons}

      {/* Zoom in button */}
      <FontAwesomeIcon
        onMouseDown={() => zoomContinuously(1.02)}
        onMouseLeave={() => {
          if (interval.current) {
            clearInterval(interval.current);
          }
        }}
        onMouseUp={() => {
          if (interval.current) {
            clearInterval(interval.current);
          }
        }}
        onClick={() =>
          setStageState(state => ({
            scale: state.scale * 1.05,
            x: state.x,
            y: state.y,
          }))
        }
        className="text-brand-gray cursor-pointer p-3 lg:w-5 lg:h-5 outline-none"
        icon={faMagnifyingGlassPlus}
        data-tooltip-content={t('blueprints.BlueprintCanvas.zoomIn')}
        data-tooltip-id="route-tooltip"
      />

      {/* Zoom out button */}
      <FontAwesomeIcon
        onMouseDown={() => zoomContinuously(0.98)}
        onMouseLeave={() => {
          if (interval.current) {
            clearInterval(interval.current);
          }
        }}
        onMouseUp={() => {
          if (interval.current) {
            clearInterval(interval.current);
          }
        }}
        onClick={() =>
          setStageState(state => ({
            scale: state.scale * 0.95,
            x: state.x,
            y: state.y,
          }))
        }
        className="text-brand-gray cursor-pointer p-3 lg:w-5 lg:h-5 outline-none"
        icon={faMagnifyingGlassMinus}
        data-tooltip-content={t('blueprints.BlueprintCanvas.zoomOut')}
        data-tooltip-id="route-tooltip"
      />

      {/* Reset zoom button */}
      <FontAwesomeIcon
        className="text-brand-gray lg:w-5 lg:h-5 cursor-pointer p-3 outline-none"
        onClick={() => resetStageState()}
        icon={faExpandArrowsAlt}
        data-tooltip-content={t('blueprints.BlueprintCanvas.resetZoom')}
        data-tooltip-id="route-tooltip"
      />

      {/* Toggle grayscale button */}
      {isToggleBlueprintColorEnabled && (
        <FontAwesomeIcon
          className={classNames(
            'cursor-pointer text-brand-gray rounded-md p-3 lg:w-5 lg:h-5 outline-none',
            {
              'bg-brand-gray-light-3': grayscale,
            },
          )}
          onClick={() => setGrayscale(val => !val)}
          icon={faPalette}
          data-tooltip-content={t('blueprints.BlueprintCanvas.toggleColor')}
          data-tooltip-id="route-tooltip"
        />
      )}

      {/* Calendar button */}
      <FontAwesomeIcon
        className={classNames(
          'cursor-pointer text-brand-blue rounded-md p-3 lg:w-5 lg:h-5 outline-none',
          {
            'bg-brand-gray-light-3': showCalendarModal,
          },
        )}
        onClick={() => setShowCalendarModal(true)}
        icon={faCalendar}
        data-tooltip-content={t('blueprints.BlueprintCanvas.timePeriod')}
        data-tooltip-id="route-tooltip"
      />
      <DatePickerInputModal
        initialStartDate={timeFrom}
        initialEndDate={timeTo}
        onSelect={({ start, end }) => {
          if (start && end) onDateRangeSelect({ start, end });
        }}
        showPredefinedRanges
        show={showCalendarModal}
        setShow={setShowCalendarModal}
      />

      {bottomButtons}
    </div>
  );
};
