import classNames from 'classnames';
import Konva from 'konva';
import { Context } from 'konva/lib/Context';
import 'konva/lib/shapes/Image';
import 'konva/lib/shapes/Label';
import 'konva/lib/shapes/Text';
import { sortBy } from 'lodash';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Group,
  Image,
  Layer,
  Path,
  Rect,
  Shape,
  Stage,
  Text,
} from 'react-konva/lib/ReactKonvaCore';

import { SensorIconAndLabelConfig } from 'components/BlueprintCanvas/components/types';
import {
  MAX_SCALE,
  MIN_SCALE,
  PERFECT_DRAW_ENABLED,
  SCALE_STEPS,
} from 'components/CanvasStage/CanvasStage';
import { LABEL_CONFIG } from 'components/CanvasStage/components/LabelConfig';
import { PIN_CONFIG } from 'components/CanvasStage/components/PinConfig';
import { ImageSize, StageState } from 'components/CanvasStage/types';
import { isMac } from 'utils/browsers';
import environment from 'utils/environment';
import { useIsPressingModKey } from 'utils/hooks';
import { isFeatureEnabled } from 'utils/isFeatureEnabled';
export type MainStageProps = {
  stageRef: React.MutableRefObject<Konva.Stage> | undefined;
  backgroundImage: HTMLImageElement | undefined;
  stageHeight: number;
  stageState: StageState;
  enableWheelScrolling?: boolean;
  enableDragging?: boolean;
  grayscale: boolean;
  imageSize: ImageSize;
  setStageState: React.Dispatch<React.SetStateAction<StageState>>;
  onClick: (({ evt, target }: Konva.KonvaEventObject<MouseEvent>) => void) | undefined;
  onDragEnd?: ({ target }: Konva.KonvaEventObject<DragEvent>) => void;
  onDragStart?: ({ target }: Konva.KonvaEventObject<DragEvent>) => void;
  sensorIconAndLabelConfigs: SensorIconAndLabelConfig[];
};

export const MainStage: React.FC<MainStageProps> = ({
  stageRef,
  backgroundImage,
  stageHeight,
  stageState,
  enableWheelScrolling = false,
  enableDragging = false,
  grayscale,
  imageSize,
  setStageState,
  onClick,
  onDragEnd,
  onDragStart,
  sensorIconAndLabelConfigs = [],
}) => {
  const backgroundImageRef = useRef<Konva.Image>() as MutableRefObject<Konva.Image> | undefined;
  const showScrollZoomOverlayTimeout = useRef<NodeJS.Timeout>();

  const [showScrollZoomOverlay, setShowScrollZoomOverlay] = useState(false);

  const { t } = useTranslation('components');
  const isPressingModKey = useIsPressingModKey();

  useEffect(() => {
    if (isPressingModKey) {
      clearTimeout(showScrollZoomOverlayTimeout.current);
      setShowScrollZoomOverlay(false);
    }
  }, [isPressingModKey]);

  const triggerScrollZoomOverlay = () => {
    if (!showScrollZoomOverlay) {
      setShowScrollZoomOverlay(true);

      clearTimeout(showScrollZoomOverlayTimeout.current);
      showScrollZoomOverlayTimeout.current = setTimeout(() => {
        setShowScrollZoomOverlay(false);
      }, 2000);
    }
  };

  const handleWheel = ({ evt, target }: Konva.KonvaEventObject<WheelEvent>) => {
    if (!isPressingModKey) {
      triggerScrollZoomOverlay();
      return;
    }
    evt.preventDefault();

    const scaleBy = SCALE_STEPS;
    const stage = target.getStage();
    if (!stage) return;

    const oldScale = stage?.scaleX();
    const pointerPosition = stage.getPointerPosition()!;
    const mousePointTo = {
      x: pointerPosition.x / oldScale - stage.x() / oldScale,
      y: pointerPosition.y / oldScale - stage.y() / oldScale,
    };

    const newScale = evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    if (newScale < MIN_SCALE || MAX_SCALE < newScale) return;

    setStageState({
      scale: newScale,
      x: -(mousePointTo.x - pointerPosition.x / newScale) * newScale,
      y: -(mousePointTo.y - pointerPosition.y / newScale) * newScale,
    });
  };

  // Handle dragging (panning)
  const handleDrag = ({ evt, target }: Konva.KonvaEventObject<DragEvent>) => {
    evt.preventDefault();

    const stage = target.getStage();
    if (!stage) return;

    const scale = stage?.scaleX();
    const pointerPosition = stage.getPointerPosition()!;
    const mousePointTo = {
      x: pointerPosition.x / scale - stage.x() / scale,
      y: pointerPosition.y / scale - stage.y() / scale,
    };

    setStageState({
      scale,
      x: -(mousePointTo.x - pointerPosition.x / scale) * scale,
      y: -(mousePointTo.y - pointerPosition.y / scale) * scale,
    });
  };
  const pinBaseWidth = 50;
  const pinBaseHeight = 50;

  const scaledPinWidth = pinBaseWidth / stageState.scale;
  const scaledPinHeight = pinBaseHeight / stageState.scale;

  const sensorIconsAndLabels = constructSensorIconsAndLabels(
    sensorIconAndLabelConfigs,
    imageSize,
    stageState.scale,
    scaledPinWidth,
    scaledPinHeight,
  );

  const isToggleBlueprintColorEnabled = isFeatureEnabled('toggleBlueprintColor', environment);

  const imageSizeIsValid = imageSize.height !== 0 && imageSize.width !== 0;

  return (
    <div className="relative">
      <div
        className={classNames(
          'w-full absolute top-0 left-0 z-10 flex items-center justify-center pointer-events-none bg-brand-gray',
          {
            'transition ease-in-out duration-1000': !isPressingModKey,
            'bg-opacity-70': showScrollZoomOverlay,
            'bg-opacity-0': !showScrollZoomOverlay,
          },
          {
            hidden: isPressingModKey,
          },
        )}
        style={{ height: stageHeight }}
      >
        <p
          className={classNames('text-xl text-white', {
            'transition ease-in-out duration-1000': !isPressingModKey,
            'opacity-100': showScrollZoomOverlay,
            'opacity-0': !showScrollZoomOverlay,
          })}
        >
          {t('CanvasStage.components.MainStage.holdModKeyForScroll.use')}
          {isMac() ? (
            <span
              className="relative font-bold ml-2 mr-1"
              style={{ fontSize: '1.6rem', top: '1px' }}
            >
              {'\u2318'}
            </span>
          ) : (
            <samp className="font-bold ml-2 mr-1 tracking-tighter">Ctrl</samp>
          )}
          + {t('CanvasStage.components.MainStage.holdModKeyForScroll.scrollToZoom')}
        </p>
      </div>
      <Stage
        height={stageHeight}
        ref={stageRef}
        onWheel={enableWheelScrolling ? handleWheel : undefined}
        onDragEnd={handleDrag}
        onDragMove={onDragEnd}
        onDragStart={onDragStart}
        scaleX={stageState.scale}
        scaleY={stageState.scale}
        x={stageState.x}
        y={stageState.y}
        draggable={enableDragging}
      >
        <Layer>
          <Image
            class
            ref={backgroundImageRef}
            filters={
              isToggleBlueprintColorEnabled && grayscale ? [Konva.Filters.Grayscale] : undefined
            }
            perfectDrawEnabled={PERFECT_DRAW_ENABLED}
            image={backgroundImage}
            onClick={onClick}
          />

          {imageSizeIsValid && sensorIconsAndLabels}
        </Layer>
      </Stage>
    </div>
  );
};

const constructSensorIconsAndLabels = (
  sensorIconAndLabelConfigs: SensorIconAndLabelConfig[],
  blueprintImageSize: ImageSize,
  stageScale: number,
  scaledWidth: number,
  scaledHeight: number,
) =>
  sortBy(sensorIconAndLabelConfigs, config => config.isActive).map((config, index) => {
    const pinWidth = config.isActive ? scaledWidth * PIN_CONFIG.sensorPinActiveScale : scaledWidth;
    const pinHeight = config.isActive
      ? scaledHeight * PIN_CONFIG.sensorPinActiveScale
      : scaledHeight;

    return (
      <Group
        key={index}
        visible={config.isVisible}
        isEnabled={config.isEnabled}
        onMouseEnter={config.onMouseEnter}
        onMouseLeave={config.onMouseLeave}
        onClick={config.onClick}
        blueprintPositionId={config.positionId}
      >
        {config.showTooltip &&
          constructSensorLabelsFromConfig(
            config,
            stageScale,
            blueprintImageSize,
            scaledWidth,
            scaledHeight,
          )}
        <Group
          x={config.xPos * blueprintImageSize.width - pinWidth / 2}
          y={config.yPos * blueprintImageSize.height - pinHeight}
          width={pinWidth}
          height={pinHeight}
          pinWidth={pinWidth}
          pinHeight={pinHeight}
          draggable={config.isDraggable}
          onDragStart={config.onDragStart}
          onDragEnd={config.onDragEnd}
          blueprintPositionId={config.positionId}
        >
          <Shape
            sceneFunc={(context: Context, shape) => {
              context.scale(pinWidth, pinHeight);
              context.beginPath();
              context.arc(0.5, 0.3, 0.35, 2.75, 6.65, false);
              context.lineTo(0.5, 1);
              context.closePath();
              context.fillStrokeShape(shape);
            }}
            fill={config.color}
            stroke={PIN_CONFIG.pinStrokeColor}
            strokeWidth={PIN_CONFIG.pinStrokeWidth}
            fillAfterStrokeEnabled={true}
          />
          {constructLogoPath(pinWidth)}
        </Group>
      </Group>
    );
  });

const constructLogoPath = (parentWidth: number) => {
  const targetWidth = parentWidth * PIN_CONFIG.logoWidthToPinWidthRatio;
  const targetHeight = targetWidth * PIN_CONFIG.logoWidthToHeightRatio;

  const xScale = targetWidth / PIN_CONFIG.logoSvgBaseSize.width;
  const yScale = targetHeight / PIN_CONFIG.logoSvgBaseSize.height;

  return (
    <Path
      x={PIN_CONFIG.logoXOffset * (targetWidth / 2)}
      y={PIN_CONFIG.logoYOffset * targetHeight}
      scaleX={xScale}
      scaleY={yScale}
      data="M360 565.8c0-197.6 108.5-348.3 286.8-385V65.3A580.2 580.2 0 0 0 66.6 645.5v11h301.7a485.2 485.2 0 0 1-8.4-90.7M1097 476.7c5.1 28.4 7.8 58.1 7.8 89.2 0 196.2-109.5 348.8-285.1 386.2v115.7a580.2 580.2 0 0 0 580.2-580.2v-11zM947.7 565.8c0-163.9-84.3-274.6-215.3-274.6C601.3 291.2 517 402 517 565.8c0 165.4 84.3 276.1 215.4 276.1 131 0 215.3-110.7 215.3-276.1"
      fill={'white'}
    />
  );
};

const constructSensorLabelsFromConfig = (
  config: SensorIconAndLabelConfig,
  stageScale: number,
  imageSize: ImageSize,
  scaledPinWidth: number,
  scaledPinHeight: number,
) => {
  const [sensorNameText, bodyText] = config.labelText.split('\n');
  const headerFontSize = LABEL_CONFIG.headerFontSize / stageScale;
  const bodyFontSize = LABEL_CONFIG.bodyFontSize / stageScale;

  const backgroundRectX = config.xPos * imageSize.width;
  const backgroundRectY =
    config.yPos * imageSize.height - scaledPinHeight * LABEL_CONFIG.backgroundRectYOffset;

  const rectWidth = scaledPinWidth * LABEL_CONFIG.labelWidthToPinWidthRatio;
  const rectHeight = scaledPinHeight * LABEL_CONFIG.labelHeightToPinHeightRatio;

  const sensorNameTextY = backgroundRectY + rectHeight * 0.5 - headerFontSize;
  const bodyTextY = backgroundRectY + rectHeight * 0.5 + bodyFontSize * 0.2;
  return (
    <>
      <Rect
        x={backgroundRectX}
        y={backgroundRectY}
        width={rectWidth}
        height={rectHeight}
        cornerRadius={[0, 100, 100, 0]}
        fill={config.color}
      />
      <Text
        x={config.xPos * imageSize.width + scaledPinWidth / 2}
        y={sensorNameTextY}
        width={rectWidth * LABEL_CONFIG.textToLabelWidthRatio}
        fontSize={headerFontSize}
        align={LABEL_CONFIG.textAlign}
        text={sensorNameText}
        fontFamily={LABEL_CONFIG.fontFamily}
        wrap={LABEL_CONFIG.wrap}
        ellipsis={LABEL_CONFIG.elipsis}
        fill={LABEL_CONFIG.textColor}
      />
      <Text
        x={config.xPos * imageSize.width + scaledPinWidth / 2}
        y={bodyTextY}
        width={rectWidth * LABEL_CONFIG.textToLabelWidthRatio}
        fontSize={bodyFontSize}
        align={LABEL_CONFIG.textAlign}
        text={bodyText}
        fontFamily={LABEL_CONFIG.fontFamily}
        wrap={LABEL_CONFIG.wrap}
        ellipsis={LABEL_CONFIG.elipsis}
        fill={LABEL_CONFIG.textColor}
      />
    </>
  );
};
