import { Dispatch, SetStateAction } from 'react';

import GatewaysHealthIndicatorModal from 'components/modals/GatewaysHealthIndicatorModal';
import { useTimePeriod } from 'utils/hooks';
import { useGatewayHeartbeats } from 'utils/hooks/data';

const GatewayHealthIndicatorModal: React.FC<{
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  gatewayId?: string;
}> = ({ show, setShow, gatewayId }) => {
  const {
    timePeriod: [timeFrom, timeTo],
  } = useTimePeriod();

  const { heartbeats } = useGatewayHeartbeats(
    gatewayId!,
    {
      fromTimestamp: timeFrom,
      toTimestamp: timeTo,
    },
    {
      enableGet: show,
    }
  );

  return <GatewaysHealthIndicatorModal show={show} setShow={setShow} heartbeats={heartbeats} />;
};

export default GatewayHealthIndicatorModal;
