import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import UsersInlineList from 'components/UsersInlineList';
import environment from 'utils/environment';
import { isFeatureEnabled } from 'utils/isFeatureEnabled';
import User from 'utils/types/User';

export const ResolvedBy = ({ user, showSeparator }: { user?: User; showSeparator: boolean }) => {
  const { t } = useTranslation('components');

  const isResolveAlertEnabled = isFeatureEnabled('resolveAlert', environment);

  const showResolvedBy = isResolveAlertEnabled && user && user?.full_name !== 'Woodsense Robot';

  if (!showResolvedBy) return null;

  return (
    <>
      {showResolvedBy && (
        <div
          className={classNames('flex flex-row gap-1 items-center', {
            'border-l-[2px] border-brand-gray-light-2 pl-2': showResolvedBy && showSeparator,
          })}
        >
          <span className="text-brand-gray-light-1 text-xs">
            {t('components:Alerts.AlertActionsStatus.resolvedBy')}
          </span>
          <UsersInlineList
            users={user ? [user] : []}
            size="md"
            data-tooltip-content={`${t('components:Alerts.AlertActionsStatus.resolvedBy')} ${user?.full_name}`}
            data-tooltip-id="route-tooltip"
          />
        </div>
      )}
    </>
  );
};
