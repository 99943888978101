import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from 'components';
import Spinner from 'components/Spinner';
import {
  brandGreen,
  brandOrange,
  moistureSateInactive,
  moistureStateBetween0And10,
  moistureStateBetween10And15,
  moistureStateBetween15And20,
  moistureStateBetween20And25,
  moistureStateBetween25And30,
  moistureStateBetween30And35,
  moistureStateBetween35And40,
  moistureStateBetween40And100,
  moistureStateInvalid,
} from 'utils/colors';
import MoistureState from 'utils/enums/MoistureState';
import { getMoistureStateText } from 'utils/sensor/texts';

export const MoistureStateBadge: React.FC<
  { state?: MoistureState } & HTMLAttributes<HTMLSpanElement>
> = ({ state, className, ...props }) => {
  const { t } = useTranslation('components');

  if (!state) return <Spinner className="ml-2 text-brand-gray-light-1" />;

  const bgColor = (() => {
    switch (state) {
      case MoistureState.MOISTURE_0_10:
        return moistureStateBetween0And10;
      case MoistureState.MOISTURE_10_15:
        return moistureStateBetween10And15;
      case MoistureState.MOISTURE_15_20:
        return moistureStateBetween15And20;
      case MoistureState.MOISTURE_20_25:
        return moistureStateBetween20And25;
      case MoistureState.MOISTURE_25_30:
        return moistureStateBetween25And30;
      case MoistureState.MOISTURE_30_35:
        return moistureStateBetween30And35;
      case MoistureState.MOISTURE_35_40:
        return moistureStateBetween35And40;
      case MoistureState.MOISTURE_40_100:
        return moistureStateBetween40And100;
      case MoistureState.INACTIVE:
        return moistureSateInactive;
      case MoistureState.INVALID:
        return moistureStateInvalid;
      case MoistureState.CREATED:
        return brandGreen;
      case MoistureState.UNKNOWN:
        return brandOrange;
      default:
        return brandOrange;
    }
  })();

  return (
    <Badge
      className={classNames('text-xs font-medium text-white', className)}
      style={{ backgroundColor: bgColor }}
      data-tooltip-content={
        state === MoistureState.INVALID ? t('badges.MoistureStateBadge.invalidTooltip') : undefined
      }
      data-tooltip-id="route-tooltip"
      {...props}
    >
      {getMoistureStateText(state)}
    </Badge>
  );
};
