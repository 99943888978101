import { differenceInDays } from 'date-fns/differenceInDays';
import { format } from 'date-fns/format';
import {
  BrushOption,
  CallbackDataParams,
  LegendOption,
  LineSeriesOption,
  TooltipOption,
  XAXisOption,
} from 'echarts/types/dist/shared';
import max from 'lodash/max';
import min from 'lodash/min';
import { useTranslation } from 'react-i18next';

import BasePlot from 'components/plots/helpers/BasePlot';
import { lg } from 'utils/breakpoints';
import { useWindowSize, useTimePeriod } from 'utils/hooks';
import { getTimeAxisTicks } from 'utils/plots/axis-formatters';
import { axisText } from 'utils/plots/axis-texts';
import { brushOptions } from 'utils/plots/brush-select';
import { lineStyle, grid, gridMobile } from 'utils/plots/plot-config';
import toolboxSettings from 'utils/plots/toolbox';
import { tooltipFormatterGateways } from 'utils/plots/tooltip-texts';
import GatewayHeartbeat from 'utils/types/GatewayHeartbeat';
import { GatewayHeartbeatDataField, DataTuple } from 'utils/types/PlotTypes';

export type MultiGatewayHeartbeatsPlotProps = {
  heartbeats?: GatewayHeartbeat[];
  dataField: GatewayHeartbeatDataField;
  formatter: string;
};

const MultiGatewayHeartbeatsPlot: React.FC<MultiGatewayHeartbeatsPlotProps> = ({
  heartbeats,
  dataField,
  formatter,
}) => {
  const { t } = useTranslation();

  const [width] = useWindowSize();

  const {
    timePeriod: [timeFrom, timeTo],
  } = useTimePeriod();

  const timeAxisTicks = getTimeAxisTicks(width);
  const fontSize = width > lg ? 14 : 12;

  const daysAmount = differenceInDays(timeTo, timeFrom);

  // Construct series
  const series: LineSeriesOption[] = [];
  const gatewayIds = Array.from(new Set(heartbeats?.map(x => x.gateway_id)));
  for (const gatewayId of gatewayIds) {
    // TODO: Maybe do not connect points when invalid
    // https://stackoverflow.com/questions/47799506/hide-time-gaps-between-poinof-line-chart
    const gatewayHeartbeats = (heartbeats || []).filter(x => x.gateway_id === gatewayId);

    const data: DataTuple[] = gatewayHeartbeats?.map(x => [
      x.timestamp,
      x[dataField as keyof GatewayHeartbeat] as number,
      x.id,
    ]);

    const serie: LineSeriesOption = {
      name: gatewayId,
      type: 'line',
      symbolSize: 5,
      lineStyle,
      data,
    };

    series.push(serie);
  }

  // Define y-axis interval
  const yValues = series
    ?.map(serie => (serie.data as DataTuple[])?.map(x => x[1]))
    .flat()
    .filter(x => !!x);

  const [yAxisMin, yAxisMax] = defineYAxisMinMax(yValues, dataField);

  const legend = {
    show: gatewayIds && gatewayIds.length > 1,
    type: 'scroll',
    orient: 'horizontal',
    textStyle: {
      fontSize,
      fontFamily: 'Montserrat, sans-serif',
    },
    data: gatewayIds,
    formatter: (name: string) => name,
    top: 0,
  } as LegendOption;

  return (
    <BasePlot
      option={{
        color: [
          '#c23531',
          '#2f4554',
          '#61a0a8',
          '#d48265',
          '#91c7ae',
          '#749f83',
          '#ca8622',
          '#bda29a',
          '#6e7074',
          '#546570',
          '#c4ccd3',
        ],
        xAxis: {
          type: 'time',
          name: t(
            'components:plots.GatewayHeartbeatPlots.MultiGatewayHeartbeatsPlot.xAxisLabel.name',
          ),
          nameGap: 24,
          min: timeFrom,
          max: timeTo,
          splitNumber: timeAxisTicks,
          nameLocation: 'middle',
          axisTick: {},
          nameTextStyle: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize,
            fontWeight: 'bold',
          },
          axisLabel: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize,
            formatter: (value: string) => {
              const date = new Date(value);
              if (daysAmount <= 4) {
                return format(date, 'dd/MM HH:mm');
              }
              return format(date, 'dd/MM');
            },
          },
        } as XAXisOption,
        yAxis: [
          {
            type: 'value',
            name: axisText(dataField),
            min: yAxisMin,
            max: yAxisMax,
            axisLabel: {
              fontFamily: 'Montserrat, sans-serif',
              fontSize,
              formatter,
            },
            nameTextStyle: {
              fontFamily: 'Montserrat, sans-serif',
              fontSize,
              fontWeight: 'bold',
            },
            nameLocation: 'middle',
            nameGap: width > lg ? 47 : 37,
          },
        ],
        tooltip: {
          axisPointer: {
            animation: true,
          },
          formatter: ({ seriesName, data }: CallbackDataParams) =>
            tooltipFormatterGateways(data as DataTuple, seriesName!, dataField),
        } as TooltipOption,
        series,
        animation: heartbeats && heartbeats.length < 100,
        legend,
        brush: brushOptions as BrushOption,
        toolbox: toolboxSettings({
          saveAsImageFilename: dataField,
          timePeriod: [timeFrom, timeTo],
        }),
        grid: width > lg ? grid : gridMobile,
      }}
    />
  );
};

export default MultiGatewayHeartbeatsPlot;

const defineYAxisMinMax = (yValues: number[], dataField: GatewayHeartbeatDataField) => {
  const minYValue = yValues.length === 0 ? NaN : (min(yValues) as number);
  const maxYValue = yValues.length === 0 ? NaN : (max(yValues) as number);

  if (dataField === 'meta_cpu_usage') {
    const yAxisMin = 0;
    const yAxisMax = isFinite(maxYValue)
      ? Math.min(Math.floor((maxYValue + 10) / 10) * 10, 100)
      : 100;
    return [yAxisMin, yAxisMax];
  } else if (dataField === 'meta_memory_usage') {
    const yAxisMin = 0;
    const yAxisMax = isFinite(maxYValue)
      ? Math.min(Math.floor((maxYValue + 10) / 10) * 10, 100)
      : 100;
    return [yAxisMin, yAxisMax];
  } else if (dataField === 'meta_sd_card_usage') {
    const yAxisMin = 0;
    const yAxisMax = isFinite(maxYValue)
      ? Math.min(Math.floor((maxYValue + 10) / 10) * 10, 100)
      : 100;
    return [yAxisMin, yAxisMax];
  } else if (dataField === 'meta_rootfs_usage') {
    const yAxisMin = 0;
    const yAxisMax = isFinite(maxYValue)
      ? Math.min(Math.floor((maxYValue + 10) / 10) * 10, 100)
      : 100;
    return [yAxisMin, yAxisMax];
  } else if (dataField === 'meta_modem_signal_rssi') {
    const yAxisMin = isFinite(minYValue)
      ? Math.min(0, Math.max(Math.floor((minYValue - 10.0) / 10) * 10, -130))
      : -130;
    const yAxisMax = isFinite(maxYValue) ? Math.min(Math.floor((maxYValue + 10) / 10) * 10, 0) : 0;
    return [yAxisMin, yAxisMax];
  } else {
    throw Error(`Invalid dataField provided: ${dataField}`);
  }
};
