import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

interface SensorSearchProps {
  onSearch: (search: string) => void;
}

export const SensorSearchbar: React.FC<SensorSearchProps> = ({ onSearch }) => {
  const { t } = useTranslation('components');
  return (
    <div className="flex items-center w-full text-xs py-2 pt-1 px-2 bg-white rounded focus-within:outline-brand-blue focus-within:outline focus-within:outline-offset-[-2px]">
      <FontAwesomeIcon className="mr-1" icon={faSearch} />
      <input
        className="h-full grow focus:outline-none"
        placeholder={t('blueprints.Sidebar.addSensors.searchPlaceholder')}
        onChange={e => debounce(() => onSearch(e.target.value), 250)()}
      />
    </div>
  );
};
