import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useMemo } from 'react';

import { HubSpotDealLink, Table, TableLink } from 'components';
import GroupLinkWithPreview from 'components/GroupLinkWithPreview';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import { userId2NameFunc } from 'utils/formatting';
import { useAdminAirtable, useCurrentUserCustomers, useCurrentUserUsers } from 'utils/hooks/data';
import { customerId2CustomerFunc } from 'utils/mapping';
import SalesOrder from 'utils/types/SalesOrder';

export const SalesOrdersTable: React.FC<{
  tableIdentifier: string;
  data: SalesOrder[];
  isPending?: boolean;
  onClick?: (row?: SalesOrder) => void;
}> = ({ tableIdentifier, data, isPending, ...props }) => {
  const { users } = useCurrentUserUsers();
  const { customers } = useCurrentUserCustomers();
  const { airtableProductInventories } = useAdminAirtable();

  const columns = useMemo(() => {
    const userId2Name = userId2NameFunc(users);
    const customerId2Customer = customerId2CustomerFunc(customers);
    const productInventoryId2Name = Object.fromEntries(
      (airtableProductInventories || []).map(productInventory => [
        productInventory.id,
        productInventory.fields['Product Name'],
      ]),
    );

    const columnHelper = createColumnHelper<SalesOrder>();

    return [
      columnHelper.accessor('delivery_group.name', {
        id: 'delivery_group_name',
        enableColumnFilter: true,
        meta: { hidden: true },
      }),

      columnHelper.accessor('project_group.name', {
        id: 'project_group_name',
        enableColumnFilter: true,
        meta: { hidden: true },
      }),

      columnHelper.accessor('airtable_sales_order_name', {
        id: 'airtable_sales_order_name',
        header: () => 'Sales order name',
        enableColumnFilter: false,
        size: 300,
      }),

      columnHelper.accessor('hs_deal_id', {
        id: 'hs_deal_id',
        header: () => 'HubSpot deal',
        cell: ({ getValue }) => <HubSpotDealLink hubSpotDealId={getValue()} />,
      }),

      columnHelper.accessor('delivery_group', {
        id: 'delivery_group',
        header: () => 'Delivery group',
        size: 150,
        enableColumnFilter: false,
        cell: ({ getValue }) =>
          getValue() ? (
            <GroupLinkWithPreview sensorGroup={getValue()} textClassName="text-sm" showIcon />
          ) : (
            'n/a'
          ),
      }),

      columnHelper.accessor('project_group', {
        id: 'project_group',
        header: () => 'Project group',
        size: 150,
        enableColumnFilter: false,
        cell: ({ getValue }) =>
          getValue() ? (
            <GroupLinkWithPreview sensorGroup={getValue()} textClassName="text-sm" showIcon />
          ) : (
            'n/a'
          ),
      }),

      columnHelper.accessor('sensors_selected', {
        id: 'sensors_selected',
        header: () => 'Sensors',
        enableColumnFilter: false,
        size: 200,
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          const count = value === '' ? 0 : value.split(',').length;
          return count === 0 ? (
            <samp>n/a</samp>
          ) : (
            <>
              <samp>{count}</samp> x {original.sensors_product_name}
            </>
          );
        },
      }),

      columnHelper.accessor('gateways_selected', {
        id: 'gateways_selected',
        header: () => 'Gateways',
        enableColumnFilter: false,
        size: 200,
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          const count = value === '' ? 0 : value.split(',').length;
          return count === 0 ? (
            <samp>n/a</samp>
          ) : (
            <>
              <samp>{count}</samp> x {original.gateways_product_name}
            </>
          );
        },
      }),

      columnHelper.accessor('customer_id', {
        id: 'customer_id',
        header: () => 'Customer',
        enableColumnFilter: false,
        size: 500,
        cell: ({ getValue }) => {
          const value = getValue();
          const customer = customerId2Customer[value];
          return (
            <TableLink
              name={customer ? customer.name : value}
              url={`/admin/customers/${value}`}
              externalDatatip="Open in new tab"
            />
          );
        },
      }),

      columnHelper.accessor('postnord_tracking_number', {
        id: 'postnord_tracking_number',
        header: () => 'Tracking number',
        enableColumnFilter: false,
        size: 400,
        cell: ({ row }) => {
          const trackingNumberPostNord = row.original.postnord_tracking_number;
          const trackingNumberUPS = row.original.ups_tracking_number;
          return trackingNumberPostNord ? (
            <a
              className="underline text-brand-green hover:text-blue-500"
              href={`https://tracking.postnord.com/en/?id=${trackingNumberPostNord}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <samp className="mr-2">{trackingNumberPostNord}</samp>
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
          ) : trackingNumberUPS ? (
            <a
              className="underline text-brand-green hover:text-blue-500"
              href={`https://www.ups.com/track?tracknum=${trackingNumberUPS}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <samp className="mr-2">{trackingNumberUPS}</samp>
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
          ) : (
            <></>
          );
        },
      }),

      columnHelper.accessor('created_date', {
        header: () => 'Completed date',
        id: 'created_date',
        enableColumnFilter: false,
        sortingFn: 'datetime',
        sortDescFirst: true,
        size: 150,
        cell: ({ getValue }) => (
          <span
            data-tooltip-content={dateToLocaleString(getValue())}
            data-tooltip-id="route-tooltip"
          >
            {dateToPrettyDate(getValue())}
          </span>
        ),
      }),

      columnHelper.accessor('created_by', {
        id: 'created_by',
        header: () => 'Completed by',
        size: 300,
        enableColumnFilter: false,
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) return <>n/a</>;
          const name = userId2Name[value];
          const text = name || value;
          return (
            <TableLink
              name={<samp>{text}</samp>}
              url={`/user/organization/users/${value}`}
              externalDatatip="Open in new tab"
            />
          );
        },
      }),

      columnHelper.accessor('user_emails_invited', {
        id: 'user_emails_invited',
        header: () => 'Users invited',
        enableColumnFilter: false,
        size: 350,
        cell: ({ getValue }) => <samp>{getValue()}</samp>,
      }),

      columnHelper.accessor('other_items_selected', {
        id: 'other_items_selected',
        header: () => 'Items',
        enableColumnFilter: false,
        size: 200,
        cell: ({ getValue }) => {
          const value = getValue();
          const valuesWithNames = value?.split(',').map(x => {
            const xSplit = x.split('x');
            const count = xSplit[0];
            // Some product inventory IDs contain 'x' themselves, so we need to join the rest of the split array
            const productInventoryId = xSplit.slice(1).join('x');
            return `${count} x ${productInventoryId2Name[productInventoryId]}`;
          });
          return <samp>{valuesWithNames?.join(', ')}</samp>;
        },
      }),

      columnHelper.accessor('id', {
        id: 'id',
        header: () => 'ID',
        enableColumnFilter: false,
        cell: ({ getValue }) => <samp>{getValue()}</samp>,
      }),
    ];
  }, [customers, users, airtableProductInventories]);

  return (
    <Table
      tableIdentifier={`sales-orders-table-${tableIdentifier}`}
      data={data}
      columns={columns}
      pageSize={10}
      loading={isPending}
      showSearchBar
      sortBy={[
        {
          id: 'created_date',
          desc: true,
        },
      ]}
      {...props}
    />
  );
};
