import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GatewaysPaginatedTable } from 'components';
import { useTimePeriod } from 'utils/hooks';
import { useSensorGateways } from 'utils/hooks/data';

const SensorGateways: React.FC<{ sensorId: string }> = ({ sensorId }) => {
  const {
    timePeriod: [timeFrom, timeTo],
  } = useTimePeriod();

  const { gateways, isPending } = useSensorGateways(sensorId, {
    enableGet: true,
    fromTimestamp: timeFrom,
    toTimestamp: timeTo,
  });

  const gatewaysData = useMemo(() => gateways || [], [gateways]);

  const { t } = useTranslation('components');

  return (
    <>
      <p className="mb-2">{t('cards.sensors.SensorGatewaysCard.text')}</p>
      <GatewaysPaginatedTable
        tableIdentifier={sensorId}
        gateways={gatewaysData}
        isPending={isPending}
        showSearchBar={false}
      />
    </>
  );
};

export default SensorGateways;
