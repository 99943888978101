import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { faSquirtyGateway } from 'components/icons';
import Modal from 'components/modals/Modal';
import SensorGateways from 'components/sensors/SensorGateways';

const SensorGatewaysModal: React.FC<{
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  sensorId: string;
}> = ({ show, setShow, sensorId }) => {
  const { t } = useTranslation('components');

  return (
    <Modal
      title={
        <>
          <FontAwesomeIcon className="mr-3" icon={faSquirtyGateway} />
          {t('modals.SensorGatewaysModal.title')}
        </>
      }
      show={show}
      setShow={setShow}
      closeOnClickOutside={false}
    >
      {sensorId && <SensorGateways sensorId={sensorId} />}
    </Modal>
  );
};

export default SensorGatewaysModal;
