import { Dispatch, SetStateAction } from 'react';

import GatewaysHealthIndicatorModal from 'components/modals/GatewaysHealthIndicatorModal';
import { useTimePeriod } from 'utils/hooks';
import { useSensorGroupGatewayHeartbeats } from 'utils/hooks/data';

const GroupGatewaysHealthIndicatorModal: React.FC<{
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  groupId: string;
}> = ({ show, setShow, groupId }) => {
  const {
    timePeriod: [timeFrom, timeTo],
  } = useTimePeriod();

  const { heartbeats } = useSensorGroupGatewayHeartbeats(groupId, {
    fromTimestamp: timeFrom,
    toTimestamp: timeTo,
  });

  return <GatewaysHealthIndicatorModal show={show} setShow={setShow} heartbeats={heartbeats} />;
};

export default GroupGatewaysHealthIndicatorModal;
