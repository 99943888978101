import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import * as serviceWorker from 'serviceWorker';
import environment, { commit } from 'utils/environment';
import applyFixes from 'utils/fixes';
import { isFeatureEnabled } from 'utils/isFeatureEnabled';
import { setupSentry } from 'utils/sentry';
import router from 'utils/ws-router';

applyFixes();

if (module.hot) {
  module.hot.accept();
}

if (isFeatureEnabled('sentryMonitoring', environment)) {
  setupSentry(commit);
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<RouterProvider router={router} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
