import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from 'utils/hooks';
import { useAlertReadByUsers } from 'utils/hooks/data';

export const MarkAsReadButton = ({
  alertId,
  onClick,
}: {
  alertId: string;
  onClick?: () => void;
}) => {
  const { t } = useTranslation('components');

  const { user } = useCurrentUser();
  const { users, markAlertReadByCurrentUser } = useAlertReadByUsers(alertId);

  const isReadByCurrentUser = users?.some(u => u.id === user?.id);

  return (
    <button
      className={classNames('border rounded-lg p-2 border-brand-gray text-brand-gray', {
        'inset-0 bg-brand-gray-light-4 opacity-60': isReadByCurrentUser,
      })}
      onClick={async e => {
        e.preventDefault();
        e.stopPropagation();

        await markAlertReadByCurrentUser();
        if (onClick) onClick();
      }}
      disabled={isReadByCurrentUser}
    >
      {isReadByCurrentUser ? (
        <div className="flex items-center gap-1">
          <span>{t('buttons.MarkAsReadButton.read')}</span>
          <FontAwesomeIcon icon={faCheck} />
        </div>
      ) : (
        <>{t('buttons.MarkAsReadButton.markAsRead')}</>
      )}
    </button>
  );
};
