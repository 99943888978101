import {
  SensorsHealthIndicatorModal,
  SensorsHealthIndicatorModalProps,
} from 'components/modals/SensorsHealthIndicatorModal';
import { useGatewaySensors } from 'utils/hooks/data';

const GatewaySensorsHealthIndicatorModal: React.FC<
  Omit<SensorsHealthIndicatorModalProps, 'sensorIds'> & {
    gatewayId?: string;
  }
> = ({ gatewayId, timePeriod: [timeFrom, timeTo], ...props }) => {
  const { sensors } = useGatewaySensors(gatewayId!, {
    fromTimestamp: timeFrom,
    toTimestamp: timeTo,
  });

  if (!sensors) return <></>;

  return (
    <SensorsHealthIndicatorModal
      sensorIds={sensors.map(s => s.id)}
      timePeriod={[timeFrom, timeTo]}
      {...props}
    />
  );
};

export default GatewaySensorsHealthIndicatorModal;
