import uniqBy from 'lodash/uniqBy';
import { useMemo } from 'react';

import { useCustomerSensorGroups, useUserGroups } from 'utils/hooks/data';
import HookOptions from 'utils/types/HookOptions';

type Options = HookOptions & {
  signPrimaryImage?: boolean;
};

export const useUserAndCustomerGroups = (
  userId?: string,
  customerId?: string,
  { enableGet = true, signPrimaryImage = true }: Options = {},
) => {
  const { groups: groupsUser, isPending: isPendingUserGroups } = useUserGroups(userId, {
    enableGet,
    signPrimaryImage,
  });
  const { sensorGroups: groupsCustomer, isPending: isPendingCustomerGroups } =
    useCustomerSensorGroups(customerId, {
      enableGet,
      signPrimaryImage,
    });

  const groups = useMemo(
    () => uniqBy([...(groupsUser || []), ...(groupsCustomer || [])], 'id'),
    [groupsUser, groupsCustomer],
  );

  return {
    groups,
    isPending: isPendingUserGroups || isPendingCustomerGroups,
  };
};
