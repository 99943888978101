import classNames from 'classnames';
import React, { Dispatch, HTMLAttributes, SetStateAction } from 'react';

import { Button, Variant } from 'components';

interface DropdownProps {
  buttonText?: string | JSX.Element;
  open: boolean;
  setisOpen: Dispatch<SetStateAction<boolean>>;
  children?: React.ReactNode;
  width?: string;
  buttonWidth?: string;
  variant?: Variant;
}

export const Dropdown = React.forwardRef<
  HTMLDivElement,
  DropdownProps & Pick<HTMLAttributes<HTMLDivElement>, 'className'>
>(
  (
    { open, setisOpen, buttonText, width = 'w-56', variant, children, className, buttonWidth = '' },
    ref,
  ) => (
    <div ref={ref} className={classNames('relative inline-block text-left', buttonWidth)}>
      <div>
        <Button
          variant={variant}
          className={classNames(className, {
            'inline-flex items-center justify-center text-sm': !className,
          })}
          id="menu-button"
          onClick={() => setisOpen(open => !open)}
          aria-expanded="true"
          aria-haspopup="true"
        >
          {buttonText}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </Button>
      </div>
      {open && (
        <div
          className={`${width} origin-top-right z-20 transition-opacity absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-auto overflow-x-hidden max-h-64`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          {children}
        </div>
      )}
    </div>
  ),
);
