import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks';
import { parseAlarm } from 'utils/types/Alarm';
import HookOptions from 'utils/types/HookOptions';

type Options = HookOptions & {
  seenOnly?: boolean;
  unseenOnly?: boolean;
  resolvedOnly?: boolean;
  unresolvedOnly?: boolean;
  fromTimestamp?: Date;
  toTimestamp?: Date;
};

export const useAdminAlerts = ({
  enableGet = true,
  limit = 1000,
  offset = 0,
  seenOnly,
  unseenOnly,
  resolvedOnly,
  unresolvedOnly,
  fromTimestamp,
  toTimestamp,
}: Options = {}) => {
  const queryKey = [
    'useAdminAlerts',
    limit,
    offset,
    seenOnly,
    unseenOnly,
    resolvedOnly,
    unresolvedOnly,
    fromTimestamp,
    toTimestamp,
  ];

  const { isAdmin } = useCurrentUser();

  // Get
  const {
    data: alerts,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get('/admin/alarms', {
        params: {
          limit,
          offset,
          seen_only: seenOnly,
          unseen_only: unseenOnly,
          resolved_only: resolvedOnly,
          unresolved_only: unresolvedOnly,
          from_timestamp: fromTimestamp,
          to_timestamp: toTimestamp,
        },
      }),
    select: (alerts: any[]) => alerts.map(parseAlarm),
    enabled: enableGet && isAdmin,
  });

  return {
    alerts,
    isPending: isPending || isLoading || isFetching,
  };
};
