import Skeleton from 'react-loading-skeleton';

import { AssignedTo, ResolvedBy, SeenBy } from 'components/alerts/AlertActionsStatus/components';
import { useAlert, useAlertReadByUsers } from 'utils/hooks/data';

export const AlertActionsStatus = ({ alertId }: { alertId: string }) => {
  const { alert, isPending } = useAlert(alertId);
  const { users } = useAlertReadByUsers(alertId);

  return (
    <>
      {isPending ? (
        <Skeleton width={200} />
      ) : (
        <div className="flex flex-row items-center gap-2 truncate">
          <SeenBy users={users} />
          <ResolvedBy user={alert?.resolved_by} showSeparator={(users?.length ?? 0) > 0} />
          <AssignedTo
            user={alert?.responsible_user}
            showSeparator={(users?.length ?? 0) > 0 || !!alert?.resolved_by}
          />
        </div>
      )}
    </>
  );
};
