import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SubmittingButton } from 'components';
import ReportRevisionForm, { ReportRevisionFormProps } from 'components/forms/ReportRevisionForm';
import Modal from 'components/modals/Modal';
import { ReportRevisionFormValues } from 'utils/types/ReportRevision';

const CreateReportModal: React.FC<
  {
    onSubmit: (
      values: ReportRevisionFormValues,
      { resetForm }: { resetForm: () => void },
    ) => Promise<void>;
    setShow: Dispatch<SetStateAction<boolean>>;
    show: boolean;
  } & ReportRevisionFormProps
> = ({ onSubmit, values, setShow, show, ...props }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { t } = useTranslation('components');

  return (
    <Modal
      show={show}
      setShow={setShow}
      title={t('modals.CreateReportModal.title')}
      footer={
        <SubmittingButton
          buttonText={t('components:forms.ReportRevisionForm.buttonText')}
          submittingText={t('components:forms.ReportRevisionForm.submittingText')}
          form="reportRevisionForm"
          submitting={isSubmitting}
        />
      }
    >
      <ReportRevisionForm
        onSubmit={async (values, { resetForm }) => {
          setIsSubmitting(true);
          await onSubmit(values, { resetForm });
          setIsSubmitting(false);
        }}
        values={values}
        includeName
        hideSubmitButton
        lockSensors={!!values?.sensor_ids}
        {...props}
      />
    </Modal>
  );
};

export default CreateReportModal;
