import { differenceInDays } from 'date-fns/differenceInDays';
import { eachHourOfInterval } from 'date-fns/eachHourOfInterval';
import { format } from 'date-fns/format';
import {
  BarSeriesOption,
  BrushOption,
  LegendOption,
  LineSeriesOption,
  ScatterSeriesOption,
  TooltipOption,
  XAXisOption,
} from 'echarts/types/dist/shared';
import max from 'lodash/max';
import min from 'lodash/min';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SensorSelect } from 'components';
import InfoText from 'components/InfoText';
import BasePlot from 'components/plots/helpers/BasePlot';
import { lg } from 'utils/breakpoints';
import { brandLime } from 'utils/colors';
import { hardwareId2DescriptionFunc, hardwareId2NameFunc } from 'utils/formatting';
import { useOnMarkTimePeriod, usePlots, useWindowSize } from 'utils/hooks';
import { useSensorWeatherObservations } from 'utils/hooks/data';
import { getTimeAxisTicks } from 'utils/plots/axis-formatters';
import { axisText } from 'utils/plots/axis-texts';
import createOnBrushSelectedFn, { brushOptions } from 'utils/plots/brush-select';
import { grid, gridMobile, lineStyle } from 'utils/plots/plot-config';
import {
  tooltipTextBattery,
  tooltipTextCO2,
  tooltipTextHumidity,
  tooltipTextHumidityWeather,
  tooltipTextMoistureInvalid,
  tooltipTextMoistureInvalidResistanceTooHigh,
  tooltipTextMoistureInvalidResistanceTooLow,
  tooltipTextMoistureValid,
  tooltipTextOhms,
  tooltipTextPrecipitation,
  tooltipTextRSSI,
  tooltipTextSNR,
  tooltipTextSpreadingFactor,
  tooltipTextTemperature,
  tooltipTextTemperatureWeather,
  tooltipTextTXPower,
  tooltipTextVOC,
} from 'utils/plots/tooltip-texts';
import {
  DataField,
  DataFieldWeather,
  DataTuple,
  GroupByKey,
  WeatherDataTuple,
  WeatherPrecipitationDataTuple,
} from 'utils/types/PlotTypes';
import Sensor from 'utils/types/Sensor';
import SensorWeatherObservation from 'utils/types/SensorWeatherObservation';
import Transmission from 'utils/types/Transmission';

const invalidPointSymbolSize = 6;

export type MultiTransmissionsPlotProps = {
  transmissions?: Transmission[];
  sensors?: Sensor[];
  dataField: DataField;
  formatter: string;
  legendInTop?: boolean;
  isPending?: boolean;
  showWeatherData?: boolean;
  groupSeriesByGatewayId?: boolean;
};

const MultiTransmissionsPlot: React.FC<MultiTransmissionsPlotProps> = ({
  transmissions = [],
  sensors = [],
  dataField,
  formatter,
  legendInTop = true,
  isPending,
  showWeatherData = false,
  groupSeriesByGatewayId = false,
}) => {
  // State
  const [sensorWeather, setSensorWeather] = useState<Sensor>(() => sensors[0]);

  // Hooks
  const { t } = useTranslation();
  const [width] = useWindowSize();

  const {
    onTransmissionClick,
    timePeriod: [timeFrom, timeTo],
    highlightPeriod,
    createToolboxSettings,
  } = usePlots();

  const { sensorWeatherObservations } = useSensorWeatherObservations(
    showWeatherData ? sensorWeather?.id : undefined,
    {
      fromTimestamp: timeFrom,
      toTimestamp: timeTo,
      includeTemperature: true,
      includeHumidity: true,
      includePrecip: true,
      includeWind: true,
    },
  );

  useEffect(() => {
    if (sensorWeather?.id !== sensors[0]?.id) {
      setSensorWeather(sensors[0]);
    }
  }, [sensors]); //eslint-disable-line

  const ref = useRef<HTMLDivElement>(null);

  const { onMarkTimePeriod, onResetMarkedTimePeriod } = useOnMarkTimePeriod();

  const timeAxisTicks = getTimeAxisTicks(width);
  const fontSize = width > lg ? 14 : 12;

  const groupByKey: GroupByKey = groupSeriesByGatewayId ? 'gateway_id' : 'hardware_id';

  const hardwareId2Name = hardwareId2NameFunc(sensors);
  const hardwareId2Description = hardwareId2DescriptionFunc(sensors);

  const seriesKeys =
    groupByKey === 'hardware_id'
      ? Array.from(new Set(transmissions?.map(x => x.hardware_id)))
      : Array.from(new Set(transmissions?.map(x => x.gateway_id)));

  const daysAmount = differenceInDays(timeTo, timeFrom);
  // Construct series
  const transmissionsList = seriesKeys?.map(key =>
    transmissions?.filter(x => x[groupByKey] === key),
  );

  const weatherDataPrecip = useMemo(
    () =>
      sensorWeatherObservations && sensorWeatherObservations.length > 0
        ? sensorWeatherObservations.map(
            ({ timestamp, rain_1h }) => [timestamp, rain_1h] as WeatherPrecipitationDataTuple,
          )
        : // We need to provide a value for each hour in the period - otherwise the shown x-axis range becomes longer than defined by `timeFrom` and `timeTo`
          eachHourOfInterval({
            start: timeFrom,
            end: timeTo,
          }).map(date => [date, 0.0] as WeatherPrecipitationDataTuple),
    [sensorWeatherObservations, timeFrom, timeTo],
  );

  const series: (LineSeriesOption | ScatterSeriesOption | BarSeriesOption)[] = [];

  seriesKeys?.forEach((seriesKey, index) => {
    // TODO: Maybe do not connect points when invalid
    // https://stackoverflow.com/questions/47799506/hide-time-gaps-between-poinof-line-chart
    const transmissionsSensor = transmissionsList[index];
    const data: DataTuple[] = transmissionsSensor?.map((x: Transmission) => [
      x.timestamp,
      x[dataField as keyof Transmission] as number,
      x.id,
    ]);

    const serie: LineSeriesOption = {
      name: seriesKey,
      type: 'line',
      symbolSize: 5,
      lineStyle,
      data,
    };

    if (index === 0) {
      serie.markArea = highlightPeriod &&
        highlightPeriod[0] &&
        highlightPeriod[1] && {
          itemStyle: {
            color: brandLime,
          },
          data: [
            [
              {
                xAxis: highlightPeriod[0],
              },
              {
                xAxis: highlightPeriod[1],
              },
            ],
          ],
        };
    }

    series.push(serie);

    // Check for invalid transmissions
    const invalidTransmissions = transmissionsSensor?.filter(x => x.isInvalid);

    if (dataField === 'moisture' && invalidTransmissions.length > 0) {
      series.push({
        name: `moisture_invalid_${seriesKey}`,
        type: 'scatter',
        symbolSize: invalidPointSymbolSize,
        itemStyle: {
          borderColor: '#868e96',
          borderWidth: 1,
          color: '#adb5bd',
          opacity: 1.0,
        },
        z: 3,
        data: invalidTransmissions?.map(transmission => [
          transmission.timestamp,
          transmission.moisture,
          transmission.id,
        ]),
        yAxisIndex: 0,
      } as ScatterSeriesOption);
    }
  });

  if (showWeatherData && dataField === 'moisture') {
    if (dataField === 'moisture') {
      series.push({
        name: 'precip',
        type: 'bar',
        symbolSize: 5,
        lineStyle,
        data: weatherDataPrecip,
        yAxisIndex: 1,
        color: '#191970',
        z: 1,
      } as BarSeriesOption);
    } else {
      const weatherData = sensorWeatherObservations?.map(
        x => [x.timestamp, x[dataField as keyof SensorWeatherObservation]] as WeatherDataTuple,
      );

      series.push({
        name: `${dataField}_weather`,
        type: 'line',
        symbolSize: 5,
        lineStyle,
        data: weatherData,
      });
    }
  }

  // Define y-axis interval
  const yValues = series
    ?.map(serie => (serie.data as DataTuple[])?.map(x => x[1]))
    .flat()
    .filter(x => !!x);

  const [yAxisMin, yAxisMax] = defineYAxisMinMax(yValues, dataField);

  const legendDataWeather: DataFieldWeather[] = showWeatherData
    ? dataField === 'moisture'
      ? ['precip']
      : ([`${dataField}_weather`] as DataFieldWeather[])
    : [];

  const legend = {
    type: 'scroll',
    orient: 'horizontal',
    textStyle: {
      fontSize,
      fontFamily: 'Montserrat, sans-serif',
    },
    data: [...seriesKeys, ...legendDataWeather],
    formatter: (seriesKey: string) => {
      // For gateways we just show the gateway id
      const name = hardwareId2Name[seriesKey] || seriesKey;
      if (name === 'humidity_weather') {
        return t('common:humidity');
      }
      if (name === 'temperature_weather') {
        return t('common:temperature');
      }
      if (name === 'precip') {
        return t('common:precip');
      }
      return name;
    },
  } as LegendOption;

  // Positioning of legend
  if (legendInTop) {
    legend.top = 0;
  } else {
    legend.bottom = 60;
  }

  // Callbacks
  const tooltipFormatter = useCallback(
    ({ data, seriesName }: { data: DataTuple; seriesName: string }): string => {
      const date = data?.[0];
      const y = data?.[1];

      const isInvalidValue = seriesName?.startsWith('moisture_invalid');
      const seriesKey = isInvalidValue ? seriesName.slice(17) : seriesName;
      const sensorName = hardwareId2Name[seriesKey] || seriesKey;
      const sensorDescription = hardwareId2Description[seriesKey];

      if (sensorName === 'humidity_weather') {
        return tooltipTextHumidityWeather(date, sensorWeather?.name, y, sensorDescription);
      }

      if (sensorName === 'temperature_weather') {
        return tooltipTextTemperatureWeather(date, sensorWeather?.name, y, sensorDescription);
      }

      if (sensorName === 'precip') {
        return tooltipTextPrecipitation(date, sensorWeather?.name, y, sensorDescription);
      }

      if (dataField === 'moisture') {
        if (isInvalidValue) {
          if (y === 2) {
            return tooltipTextMoistureInvalidResistanceTooHigh(date, sensorName);
          } else if (y === 100) {
            return tooltipTextMoistureInvalidResistanceTooLow(date, sensorName);
          } else {
            return tooltipTextMoistureInvalid(date, sensorName);
          }
        }
        return tooltipTextMoistureValid(date, sensorName, y, sensorDescription);
      } else if (dataField === 'humidity') {
        return tooltipTextHumidity(date, sensorName, y, sensorDescription);
      } else if (dataField === 'temperature') {
        return tooltipTextTemperature(date, sensorName, y, sensorDescription);
      } else if (dataField === 'co2') {
        return tooltipTextCO2(date, sensorName, y, sensorDescription);
      } else if (dataField === 'voc') {
        return tooltipTextVOC(date, sensorName, y, sensorDescription);
      } else if (dataField === 'rssi') {
        return tooltipTextRSSI(date, sensorName, y, sensorDescription);
      } else if (dataField === 'spreading_factor') {
        return tooltipTextSpreadingFactor(date, sensorName, y, sensorDescription);
      } else if (dataField === 'snr') {
        return tooltipTextSNR(date, sensorName, y, sensorDescription);
      } else if (dataField === 'tx_power') {
        return tooltipTextTXPower(date, sensorName, y, sensorDescription);
      } else if (dataField === 'battery') {
        return tooltipTextBattery(date, sensorName, y, sensorDescription);
      } else if (dataField === 'ohms') {
        return tooltipTextOhms(date, sensorName, y, sensorDescription);
      }

      return 'n/a';
    },
    [hardwareId2Name, hardwareId2Description, dataField, sensorWeather?.name],
  );
  // Get max precip value
  const yMaxPrecip = useMemo(() => {
    let yMaxPrecip = 1;
    const maxPrecip = Math.max.apply(
      null,
      weatherDataPrecip.map(([date, value]: any[]) => value),
    );

    if (isFinite(maxPrecip)) {
      yMaxPrecip = maxPrecip + 0.1;
      if (yMaxPrecip % 1 !== 0) yMaxPrecip = Number(yMaxPrecip.toFixed(1));
    }

    return yMaxPrecip;
  }, [weatherDataPrecip]);

  // Define brush
  const brushSelected = createOnBrushSelectedFn(onMarkTimePeriod);
  return (
    <>
      {showWeatherData && (
        <div className="flex justify-between items-center mt-3 mb-3">
          <InfoText
            text={
              <>
                {t('components:plots.TransmissionsPlots.MultiTransmissionsPlot.infoText')}
                {`${sensorWeather?.name}`}
              </>
            }
          />
          <SensorSelect
            placeholder={t(
              'components:plots.TransmissionsPlots.MultiTransmissionsPlot.sensorSelect.placeholder',
            )}
            sensorsToSelect={sensors}
            isPending={isPending}
            initialSelectedSensor={sensorWeather}
            ref={ref}
            onSelect={sensor => setSensorWeather(sensor)}
          />
        </div>
      )}

      <BasePlot
        option={{
          color: [
            '#c23531',
            '#2f4554',
            '#61a0a8',
            '#d48265',
            '#91c7ae',
            '#749f83',
            '#ca8622',
            '#bda29a',
            '#6e7074',
            '#546570',
            '#c4ccd3',
          ],
          xAxis: {
            type: 'time',
            name: t('components:plots.TransmissionsPlots.MultiTransmissionsPlot.yAxisLabel.name'),
            nameGap: 24,
            min: timeFrom,
            max: timeTo,
            splitNumber: timeAxisTicks,
            nameLocation: 'middle',
            axisTick: {},
            nameTextStyle: {
              fontFamily: 'Montserrat, sans-serif',
              fontSize,
              fontWeight: 'bold',
            },
            axisLabel: {
              fontFamily: 'Montserrat, sans-serif',
              fontSize,
              formatter: (value: string) => {
                const date = new Date(value);
                if (daysAmount <= 4) {
                  return format(date, 'dd/MM HH:mm');
                }
                return format(date, 'dd/MM');
              },
            },
          } as XAXisOption,
          yAxis: [
            {
              type: defineYAxisType(dataField) as 'value',
              name: axisText(dataField),
              min: yAxisMin,
              max: yAxisMax,
              axisLabel: {
                fontFamily: 'Montserrat, sans-serif',
                fontSize,
                formatter,
              },
              nameTextStyle: {
                fontFamily: 'Montserrat, sans-serif',
                fontSize,
                fontWeight: 'bold',
              },
              nameLocation: 'middle',
              nameGap: width > lg ? 47 : 37,
            },
            {
              type: 'value',
              show: dataField === 'moisture' ? true : false,
              name: axisText('precip'),
              splitLine: {
                show: false,
              },
              min: 0,
              max: yMaxPrecip,
              axisLabel: {
                fontSize,
                fontFamily: 'Montserrat, sans-serif',
                formatter: '{value}',
              },
              nameTextStyle: {
                fontSize,
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 'bold',
              },
              nameLocation: 'middle',
              nameGap: width > lg ? 40 : 30,
            },
          ],
          tooltip: {
            axisPointer: {
              animation: true,
            },
            formatter: tooltipFormatter as TooltipOption,
          } as TooltipOption,
          series,
          animation: transmissions.length < 100,
          legend,
          brush: brushOptions as BrushOption,
          toolbox: createToolboxSettings({
            saveAsImageFilename: dataField,
            hardwareId: transmissions[0]?.hardware_id,
            onTimePeriodResetClick: onResetMarkedTimePeriod,
          }),
          grid: width > lg ? grid : gridMobile,
        }}
        onEvents={{
          click: useCallback(
            ({ value }: { value: DataTuple }) => {
              const transmissionId = value[2];
              if (onTransmissionClick) onTransmissionClick(transmissionId);
            },
            [onTransmissionClick],
          ),
          legendselectchanged: (
            { name, selected }: { name: string; selected: any },
            chart: any,
          ) => {
            const show = selected[name];
            const seriesName = `moisture_invalid_${name}`;
            const sensorSeries = series.find(x => x.name === seriesName);
            if (sensorSeries) {
              // Hide invalid points if any exists
              // @ts-ignore
              sensorSeries.symbolSize = show ? invalidPointSymbolSize : 0;
            }
            chart.setOption({
              series,
            });
          },

          brushSelected: brushSelected!,
        }}
      />
    </>
  );
};

export default MultiTransmissionsPlot;

const defineYAxisType = (dataField: DataField) => {
  if (dataField === 'ohms') {
    return 'log';
  }
  return 'value';
};

const defineYAxisMinMax = (yValues: number[], dataField: DataField) => {
  const minYValue = yValues.length === 0 ? NaN : (min(yValues) as number);
  const maxYValue = yValues.length === 0 ? NaN : (max(yValues) as number);

  let yAxisMin = 0;
  let yAxisMax;
  if (dataField === 'moisture') {
    yAxisMin = 0;
    yAxisMax = isFinite(maxYValue) ? Math.min(Math.floor((maxYValue + 5.0) / 5) * 5, 100) : 30;
  } else if (dataField === 'humidity') {
    yAxisMax = isFinite(maxYValue) ? Math.min(Math.floor((maxYValue + 10.0) / 10) * 10, 100) : 100;
  } else if (dataField === 'temperature') {
    yAxisMin = isFinite(minYValue)
      ? Math.min(0, Math.max(Math.floor((minYValue + 5.0) / 5) * 5, -30))
      : 0;
    yAxisMax = isFinite(maxYValue) ? Math.min(Math.floor((maxYValue + 5.0) / 5) * 5, 100) : 50;
  } else if (dataField === 'co2') {
    yAxisMax = isFinite(maxYValue)
      ? Math.min(Math.floor((maxYValue + 10.0) / 10) * 10, 5000)
      : 2000;
  } else if (dataField === 'voc') {
    yAxisMax = isFinite(maxYValue)
      ? Math.min(Math.floor((maxYValue + 10.0) / 10) * 10, 2000)
      : 2000;
  } else if (dataField === 'rssi') {
    yAxisMin = isFinite(minYValue)
      ? Math.max(Math.floor((minYValue - 10.0) / 10) * 10, -150)
      : -150;
    yAxisMax = isFinite(maxYValue) ? Math.min(Math.floor((maxYValue + 10.0) / 10) * 10, 150) : 150;
  } else if (dataField === 'spreading_factor') {
    yAxisMin = 7;
    yAxisMax = 12;
  } else if (dataField === 'snr') {
    yAxisMin = isFinite(minYValue) ? Math.max(Math.floor((minYValue - 10.0) / 10) * 10, -30) : -30;
    yAxisMax = isFinite(maxYValue) ? Math.min(Math.floor((maxYValue + 10.0) / 10) * 10, 20) : 20;
  } else if (dataField === 'tx_power') {
    yAxisMin = 0;
    yAxisMax = 20;
  } else if (dataField === 'battery') {
    yAxisMin = 0;
    yAxisMax = 4000;
  } else if (dataField === 'ohms') {
    yAxisMin = 0.01;
    yAxisMax = 1000000;
  } else {
    throw Error(`Invalid dataField provided: ${dataField}`);
  }

  return [yAxisMin, yAxisMax];
};
