import MultiTransmissionsPlot, {
  MultiGatewayHeartbeatsPlotProps,
} from 'components/plots/GatewayHeartbeatPlots/MultiGatewayHeartbeatsPlot';

type InputProps = Omit<MultiGatewayHeartbeatsPlotProps, 'dataField' | 'formatter'>;

export const MultiGatewayCpuUsagePlot: React.FC<InputProps> = ({ ...props }) => (
  <MultiTransmissionsPlot dataField="meta_cpu_usage" formatter="{value}%" {...props} />
);

export const MultiGatewayMemoryUsagePlot: React.FC<InputProps> = ({ ...props }) => (
  <MultiTransmissionsPlot dataField="meta_memory_usage" formatter="{value}%" {...props} />
);

export const MultiGatewaySDCardSpacePlot: React.FC<InputProps> = ({ ...props }) => (
  <MultiTransmissionsPlot dataField="meta_sd_card_usage" formatter="{value}%" {...props} />
);

export const MultiGatewayRootfsSpacePlot: React.FC<InputProps> = ({ ...props }) => (
  <MultiTransmissionsPlot dataField="meta_rootfs_usage" formatter="{value}%" {...props} />
);

export const MultiGatewayModemSignalPlot: React.FC<InputProps> = ({ ...props }) => (
  <MultiTransmissionsPlot dataField="meta_modem_signal_rssi" formatter="{value}" {...props} />
);
