import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { useMemo } from 'react';

import { useCustomerBlueprints, useUserBlueprints } from 'utils/hooks/data';

export const useUserAndCustomerBlueprints = (userId?: string, customerId?: string) => {
  const {
    blueprints: blueprintsUser,
    isPending: isPendingUser,
    createBlueprintByUser,
  } = useUserBlueprints(userId);
  const {
    blueprints: blueprintsCustomer,
    isPending: isPendingCustomer,
    createBlueprintByCustomer,
  } = useCustomerBlueprints(customerId);

  const blueprintsMem = useMemo(
    () =>
      orderBy(
        uniqBy([...(blueprintsUser || []), ...(blueprintsCustomer || [])], 'id'),
        'created_date',
        'desc',
      ),
    [blueprintsUser, blueprintsCustomer],
  );

  return {
    blueprints: blueprintsMem,
    isPending: isPendingCustomer || isPendingUser,
    createBlueprintByUser,
    createBlueprintByCustomer,
  };
};
