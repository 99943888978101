import {
  SensorsHealthIndicatorModal,
  SensorsHealthIndicatorModalProps,
} from 'components/modals/SensorsHealthIndicatorModal';
import { useSensorGroupSensors } from 'utils/hooks/data';

const GroupHealthIndicatorModal: React.FC<
  Omit<SensorsHealthIndicatorModalProps, 'sensorIds'> & {
    groupId?: string;
  }
> = ({ groupId, ...props }) => {
  const { sensors } = useSensorGroupSensors(groupId);

  if (!sensors) return <></>;

  return <SensorsHealthIndicatorModal sensorIds={sensors.map(s => s.id)} {...props} />;
};

export default GroupHealthIndicatorModal;
