import Skeleton, { SkeletonProps } from 'react-loading-skeleton';

import Card, { CardProps } from 'components/cards/Card';

const LoadingCard: React.FC<
  Pick<CardProps, 'header'> &
    SkeletonProps & {
      cardClassName?: string;
    }
> = ({ header, cardClassName, ...props }) => (
  <Card
    className={cardClassName}
    header={header ? header : undefined}
    body={<Skeleton {...props} />}
  />
);

export default LoadingCard;
