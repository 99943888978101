import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertCard, InfiniteScroll } from 'components';
import { faSquirtyAlert } from 'components/icons';
import { simpleHash } from 'utils/hash';
import { sortAlerts } from 'utils/sortAlerts';
import { Alarm } from 'utils/types';

export const AlertsOverviewContainer = ({
  alerts,
  isPending,
}: {
  alerts: Alarm[];
  isPending: boolean;
}) => {
  const { t } = useTranslation('components');

  const sortedAlerts = useMemo(() => sortAlerts(alerts) ?? [], [alerts]);

  const sortedAlertsIdsHash = useMemo(() => {
    const alertsIdsString = sortedAlerts?.map(sensor => sensor.id).join('');
    return simpleHash(alertsIdsString ?? '');
  }, [sortedAlerts]);

  return (
    <div className="flex flex-col mb-10">
      <h1 className="mb-4">
        <FontAwesomeIcon className="mr-3" icon={faSquirtyAlert} />
        {t('Alerts.AlertsOverviewContainer.title')}
      </h1>

      <div className="w-full">
        <div className="flex flex-row justify-between pb-2 items-end text-brand-gray text-sm">
          {t('Alerts.AlertsOverviewContainer.alertsFound', { counterAlerts: alerts.length })}
        </div>

        {!!sortedAlerts.length && (
          <InfiniteScroll
            key={sortedAlertsIdsHash}
            data={sortedAlerts ?? []}
            isPending={isPending}
            className="max-h-[400px]"
            noMoreDataPlaceholder={t('Alerts.AlertsOverviewContainer.noMoreAlerts')}
            loadingDataPlaceholder={t('Alerts.AlertsOverviewContainer.loadingAlerts')}
            noDataFoundPlaceholder={t('Alerts.AlertsOverviewContainer.noAlertsFound')}
            renderItem={alert => <AlertCard key={alert.id} alert={alert} isPending={isPending} />}
          />
        )}
      </div>
    </div>
  );
};
