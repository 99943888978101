import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/modals/Modal';
import {
  MultiGatewayCpuUsagePlot,
  MultiGatewayMemoryUsagePlot,
  MultiGatewayModemSignalPlot,
  MultiGatewayRootfsSpacePlot,
  MultiGatewaySDCardSpacePlot,
} from 'components/plots/GatewayHeartbeatPlots';
import GatewayHeartbeat from 'utils/types/GatewayHeartbeat';

const GatewaysHealthIndicatorModal: React.FC<{
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  heartbeats?: GatewayHeartbeat[];
}> = ({ show, setShow, heartbeats }) => {
  const { t } = useTranslation('components');

  return (
    <Modal
      title={t('modals.GatewaysHealthIndicatorModal.title')}
      show={show}
      setShow={setShow}
      closeOnClickOutside={false}
    >
      <div className="mb-3">
        <h2>{t('modals.GatewaysHealthIndicatorModal.MultiGatewayCpuUsagePlot.title')}</h2>
        <MultiGatewayCpuUsagePlot heartbeats={heartbeats} />
      </div>

      <div className="mb-3">
        <h2>{t('modals.GatewaysHealthIndicatorModal.MultiGatewayMemoryUsagePlot.title')}</h2>
        <MultiGatewayMemoryUsagePlot heartbeats={heartbeats} />
      </div>

      <div className="mb-3">
        <h2>{t('modals.GatewaysHealthIndicatorModal.MultiGatewayModemSignalPlot.title')}</h2>
        <MultiGatewayModemSignalPlot heartbeats={heartbeats} />
      </div>

      <div className="mb-3">
        <h2>{t('modals.GatewaysHealthIndicatorModal.MultiGatewaySDCardSpacePlot.title')}</h2>
        <MultiGatewaySDCardSpacePlot heartbeats={heartbeats} />
      </div>

      <div className="mb-3">
        <h2>{t('modals.GatewaysHealthIndicatorModal.MultiGatewayRootfsSpacePlot.title')}</h2>
        <MultiGatewayRootfsSpacePlot heartbeats={heartbeats} />
      </div>
    </Modal>
  );
};

export default GatewaysHealthIndicatorModal;
