import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import { ControlledSwitch } from 'components';
import AdminOnlyDiv from 'components/AdminOnlyDiv';
import InfoText from 'components/InfoText';
import { SensorAlertRulesMatrix } from 'components/SensorAlertRulesMatrix';
import SensorAlarmRule from 'components/sensors/SensorAlarmRule';
import { parseI18LanguageToLocale } from 'utils/enums/Locale';
import { useSensor, useSensorAlarmRules } from 'utils/hooks/data';
import { sortAlarmRules } from 'utils/sorting';

export type Props = { sensorId: string; hideToggleAlarmsButton?: boolean };

const SensorAlarmRules: React.FC<Props> = ({ sensorId, hideToggleAlarmsButton = false }) => {
  const [alarmsEnabled, setAlarmsEnabled] = useState(false);

  const { t, i18n } = useTranslation('components');
  const currentLocale = parseI18LanguageToLocale(i18n.language);

  const { sensor, updateSensor } = useSensor(sensorId);
  const { availableAlarmRules, isPending } = useSensorAlarmRules(sensorId);

  const availableAlarmRulesSorted = useMemo(
    () => sortAlarmRules(availableAlarmRules, currentLocale),
    [availableAlarmRules, currentLocale],
  );

  useEffect(() => {
    const sendAlarms = !!sensor?.send_alarms;
    if (sendAlarms !== alarmsEnabled) {
      setAlarmsEnabled(sendAlarms);
    }
  }, [sensor, alarmsEnabled]);

  if (isPending) return <Skeleton count={3} />;

  return (
    <>
      {!hideToggleAlarmsButton && (
        <ControlledSwitch
          className="mb-4"
          label={t('cards.sensors.SensorAlarmRules.toggleAlarms')}
          isChecked={alarmsEnabled}
          setIsChecked={setAlarmsEnabled}
          onChange={async e => {
            if (e.target.checked) {
              await updateSensor({ send_alarms: true });
            } else {
              await updateSensor({ send_alarms: false });
            }
          }}
        />
      )}

      <div
        className={classNames({
          'opacity-50 pointer-events-none': !sensor?.send_alarms,
        })}
      >
        <InfoText
          className="mb-3"
          text={t('cards.sensors.SensorAlarmRules.infoText')}
          data-tooltip-content={t('cards.sensors.SensorAlarmRules.infoIcon')}
          data-tooltip-id="route-tooltip"
        />

        {availableAlarmRulesSorted?.map(alarmRule => (
          <SensorAlarmRule key={alarmRule.id} alarmRuleId={alarmRule.id} sensorId={sensorId} />
        ))}
      </div>

      <Link
        className="text-brand-green hover:text-blue-500 text-xs lg:text-sm"
        to="/user/alert_rules"
      >
        {t('cards.sensors.SensorAlarmRules.toAlarmRules')}
      </Link>

      <AdminOnlyDiv useCard={false}>
        <SensorAlertRulesMatrix sensor={sensor} alertRules={availableAlarmRulesSorted} />
      </AdminOnlyDiv>
    </>
  );
};

export default SensorAlarmRules;
