import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import {
  ColumnDef,
  ColumnFiltersState,
  ColumnPinningState,
  Row,
  RowData,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { Pagination } from 'components';
import {
  IndeterminateCheckbox,
  TableContent,
  TableToolbar,
} from 'components/tables/Table/components';
import { sm } from 'utils/breakpoints';
import { useWindowSize } from 'utils/hooks';
import { useSessionStorage } from 'utils/hooks/useSessionStorage';
import { createColumnBasedTableIdentifier, localStoragePrefix } from 'utils/tables/identifier';

declare module '@tanstack/react-table' {
  // allows us to define custom properties for our columns
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'text' | 'select';
    transform?: (val: any) => string;
    className?: string;
    hideFromToggleModal?: boolean;
    hidden?: boolean;
  }
}

export type TableActionType =
  | 'create-new-group'
  | 'create-subgroup'
  | 'update-frequency'
  | 'update-customer'
  | 'update-tags'
  | 'compare-sensors'
  | 'create-report'
  | 'download-lora-keys';

export interface TableAction {
  title: string;
  action: (sensorIds: string[]) => Promise<void> | void;
  icon: IconDefinition;
}

export const possibleTablePageSizes = [5, 10, 20] as const;
export type PossibleTablePageSizes = (typeof possibleTablePageSizes)[number];

export interface TableProps<T extends {}> {
  tableIdentifier: string;
  data: T[];
  columns: ColumnDef<T, any>[];
  onClick?: (row: T) => void;
  initialColumnVisibility?: VisibilityState;
  initialColumnPinning?: ColumnPinningState;
  initialColumnFiltering?: ColumnFiltersState;
  sortBy?: SortingState;
  pageSize?: PossibleTablePageSizes;
  showTogglingColumns?: boolean;
  showTogglingFilters?: boolean;
  showSearchBar?: boolean;
  placeholder?: string;
  noDataPlaceholder?: string;
  loading?: boolean;
  hideHeader?: boolean;
  actions?: TableAction[];
}

export const Table = <T extends { id?: string }>({
  tableIdentifier,
  data,
  columns,
  onClick,
  initialColumnVisibility,
  initialColumnPinning,
  initialColumnFiltering,
  sortBy,
  pageSize = possibleTablePageSizes[1],
  actions,
  ...props
}: TableProps<T>) => {
  const [windowWidth] = useWindowSize();
  const isMobileView = windowWidth < sm;

  const [filtering, setFiltering] = useState('');

  const [rowSelection, setRowSelection] = useState({});

  // Generate a unique table identifier based on columns to share state across tables with identical columns
  const columnBasedTableIdentifier = useMemo(
    () => createColumnBasedTableIdentifier(columns),
    [columns],
  );

  // Persistence keys
  const sessionStorageKeyCurrentPage = `${localStoragePrefix}-${tableIdentifier}-page`;
  const localeStorageInitialSortingState = `${localStoragePrefix}-${tableIdentifier}-initial-sorting-state`;
  const localeStorageInitialFilteringState = `${localStoragePrefix}-${tableIdentifier}-initial-filtering-state`;
  const localeStorageInitialColumnVisibility = `${localStoragePrefix}-${columnBasedTableIdentifier}-initial-column-visibility`;

  // Save & load current page in session storage
  const [pagination, setPagination] = useSessionStorage(sessionStorageKeyCurrentPage, {
    pageIndex: 0,
    pageSize: Number(pageSize),
  });

  // Save & load state of column toggles in users localstorage
  const [columnVisibility, setColumnVisibility] = useLocalStorage(
    localeStorageInitialColumnVisibility,
    initialColumnVisibility ?? {},
  );

  // Save & load state of column sorting in users session storage
  const [sorting, setSorting] = useSessionStorage(localeStorageInitialSortingState, sortBy ?? []);

  // Save & load state of column filters in users session storage
  const [columnsFiltering, setColumnsFiltering] = useSessionStorage(
    localeStorageInitialFilteringState,
    initialColumnFiltering ?? [],
  );

  const [isFiltersVisible, setIsFiltersVisible] = useState(columnsFiltering.length > 0);

  const columnsToShow = useMemo(() => columns.filter(column => !column.meta?.hidden), [columns]); // filter out hidden columns

  const table = useReactTable({
    data,
    defaultColumn: {
      size: 100, // default starting column size, if the size is not defined in the column definition
      minSize: 70, //enforced during column resizing
      maxSize: 300, //enforced during column resizing
    },
    columns: columnsToShow,
    state: {
      columnVisibility,
      globalFilter: filtering,
      columnFilters: columnsFiltering,
      sorting,
      rowSelection,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setFiltering,
    onColumnFiltersChange: setColumnsFiltering,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      columnVisibility,
      columnPinning: initialColumnPinning ?? { left: [], right: [] },
    },
    enableColumnResizing: true,
    enableRowSelection: true,
    columnResizeMode: 'onChange',

    // Prevents the page from resetting to index 0 when a row is updated.
    // We handle page index resetting manually during global and column filtering.
    autoResetPageIndex: false,
  });

  // Add additional column for selecting items
  // if any actions to be performed are defined
  useMemo(() => {
    if (actions && actions.length > 0) {
      columnsToShow.unshift({
        id: 'selection',
        enableResizing: false,
        enableHiding: false,
        size: 45,
        meta: {
          hideFromToggleModal: true,
        },
        header: () => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          </div>
        ),
        cell: ({ row }: { row: Row<T> }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
      });
    }
  }, [actions, columnsToShow, table]);

  useEffect(() => {
    // Reset pagination when page index gets in a bad state
    if (typeof pagination.pageIndex !== 'number') {
      setPagination({
        pageIndex: 0,
        pageSize,
      });
    }
  }, [pagination, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="flex overflow-hidden flex-col shadow rounded-lg">
        <TableToolbar
          colspan={table.getTotalSize()}
          columns={table.getAllFlatColumns()}
          selectedRows={table.getSelectedRowModel().flatRows}
          filtering={filtering}
          setFiltering={setFiltering}
          isFiltersVisible={isFiltersVisible}
          setIsFiltersVisible={setIsFiltersVisible}
          resetColumnFilters={table.resetColumnFilters}
          resetPageIndex={table.resetPageIndex}
          getToggleAllColumnsVisibilityHandler={table.getToggleAllColumnsVisibilityHandler}
          getIsAllColumnsVisible={table.getIsAllColumnsVisible}
          actions={actions}
          {...props}
        />

        <TableContent
          headerGroups={table.getHeaderGroups()}
          rows={table.getRowModel().rows}
          coreRows={table.getCoreRowModel().rows}
          colspan={table.getTotalSize()}
          pageSize={table.getState().pagination.pageSize}
          showPlaceholderRows={data.length > table.getState().pagination.pageSize}
          isFiltersVisible={isFiltersVisible}
          isMobileView={isMobileView}
          onClick={onClick}
          resetPageIndex={table.resetPageIndex}
          {...props}
        />
      </div>

      <Pagination
        colspan={table.getTotalSize()}
        pageCount={table.getPageCount()}
        currentPage={table.getState().pagination.pageIndex}
        showPaginationControls={table.getPageCount() > 1}
        setPageIndex={newPageIndex => {
          table.setPageIndex(newPageIndex);
        }}
        getCanPreviousPage={() => table.getCanPreviousPage()}
        getCanNextPage={() => table.getCanNextPage()}
        pageSize={table.getState().pagination.pageSize}
        setPageSize={pageSize => table.setPageSize(pageSize)}
        dataLength={data.length}
        possiblePageSizes={[...possibleTablePageSizes]}
      />
    </>
  );
};
