import { useCurrentUser, useSelectedCustomer } from 'utils/hooks';
import { useAdminAlerts, useCustomerAlerts, useUserAlerts } from 'utils/hooks/data';

export const useAlerts = ({
  fromTimestamp,
  toTimestamp,
}: {
  fromTimestamp: Date;
  toTimestamp: Date;
}) => {
  const { customerId, showAllCustomers } = useSelectedCustomer();
  const { userId } = useCurrentUser();

  const adminAlertsResult = useAdminAlerts({
    enableGet: showAllCustomers,
    fromTimestamp,
    toTimestamp,
  });
  const userAlertsResult = useUserAlerts(userId, {
    enableGet: !showAllCustomers && !customerId,
    fromTimestamp,
    toTimestamp,
  });
  const customerAlertsResult = useCustomerAlerts(customerId, {
    enableGet: !showAllCustomers && !!customerId,
    fromTimestamp,
    toTimestamp,
  });

  const alerts = showAllCustomers
    ? adminAlertsResult.alerts
    : customerId
      ? customerAlertsResult.alerts
      : userAlertsResult.alerts;
  const isPending = showAllCustomers
    ? adminAlertsResult.isPending
    : customerId
      ? customerAlertsResult.isPending
      : userAlertsResult.isPending;

  return {
    alerts,
    isPending,
  };
};
