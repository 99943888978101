import { AlertSeverityLevel } from 'utils/enums';
import { Alarm } from 'utils/types';

export const sortAlerts = (alerts?: Alarm[]) =>
  alerts?.sort((a, b) => {
    // compare severity levels, the critical alerts are placed be first
    const severityComparison = (b.severity_level ?? 0) - (a.severity_level ?? 0);
    if (severityComparison !== 0) {
      return severityComparison;
    }

    // if both alerts are critical place unresolved first
    if (
      a.severity_level === AlertSeverityLevel.Critical &&
      b.severity_level === AlertSeverityLevel.Critical
    ) {
      if (a.resolved_time === null && b.resolved_time !== null) {
        return -1;
      }

      if (a.resolved_time !== null && b.resolved_time === null) {
        return 1;
      }
    }

    // if severity levels are the same, compare created_date
    const dateA = a.created_date ? a.created_date.getTime() : 0;
    const dateB = b.created_date ? b.created_date.getTime() : 0;
    return dateB - dateA;
  });
