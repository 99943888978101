import queryString from 'query-string';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BlueprintSensorPositioning } from 'components/BlueprintSensorPositioning';
import { BlueprintViewStateContext } from 'utils/contexts';
import { Blueprint, BlueprintViewStateType } from 'utils/types';
import Sensor from 'utils/types/Sensor';

export const SensorGroupPositioningCard = ({
  index,
  blueprint,
  timeFrom,
  timeTo,
  sensorIds,
  sensors,
}: {
  index: number;
  blueprint: Blueprint;
  timeFrom: Date;
  timeTo: Date;
  sensorIds: string[] | undefined;
  sensors: Sensor[] | undefined;
}) => {
  const { t } = useTranslation('components');

  const [blueprintViewState, setBlueprintViewState] = useState<BlueprintViewStateType>(
    BlueprintViewStateType.Moisture,
  );

  return (
    <BlueprintViewStateContext.Provider value={{ blueprintViewState, setBlueprintViewState }}>
      <div key={blueprint.id}>
        <div className="flex w-full">
          <Link
            className=" underline hover:text-brand-blue grow text-right"
            to={{
              pathname: `/user/blueprints/${blueprint.id}`,
              search: queryString.stringify({
                timeFrom: timeFrom?.toISOString(),
                timeTo: timeTo?.toISOString(),
              }),
            }}
          >
            {t('cards.sensor_groups.SensorGroupPositioningCard.blueprintDetailsLink')}
          </Link>
        </div>
        {index > 0 && <hr className="text-brand-gray-light-3" />}

        <BlueprintSensorPositioning blueprintId={blueprint.id} onlyEnableSensorIds={sensorIds} />
      </div>
    </BlueprintViewStateContext.Provider>
  );
};
