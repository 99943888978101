import uniqBy from 'lodash/uniqBy';
import { useMemo } from 'react';

import Permission from 'utils/enums/Permission';
import { useCustomerSensors, useUserSensors } from 'utils/hooks/data';
import { Options as OptionsCustomerSensors } from 'utils/hooks/data/customers/useCustomerSensors';
import { Options as OptionsUserSensors } from 'utils/hooks/data/users/useUserSensors';

type Options = OptionsCustomerSensors & OptionsUserSensors;

export const useUserAndCustomerSensors = (
  userId?: string,
  customerId?: string,
  {
    enableGet = true,
    includeTags = false,
    includeGeographicLocation = false,
    includeStates = false,
    includeAllSensors = false,
    permission = Permission.View,
  }: Options = {},
) => {
  const { sensors: sensorsUser, isPending: isPendingUserSensors } = useUserSensors(userId, {
    includeTags,
    includeGeographicLocation,
    includeStates,
    includeAllSensors,
    enableGet,
    permission,
  });
  const { sensors: sensorsCustomer, isPending: isPendingCustomerSensors } = useCustomerSensors(
    customerId,
    {
      includeTags,
      includeGeographicLocation,
      includeStates,
      enableGet,
      permission,
    },
  );

  const sensors = useMemo(
    () =>
      // We merge the sensors from the user and the customer and remove duplicates.
      uniqBy([...(sensorsUser || []), ...(sensorsCustomer || [])], 'id'),
    [sensorsUser, sensorsCustomer],
  );

  return {
    sensors,
    isPending: isPendingUserSensors || isPendingCustomerSensors,
  };
};
