import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/modals/Modal';
import AnomalyValidationType from 'utils/enums/AnomalyValidationType';
import { usePlots } from 'utils/hooks';

const AnomalyFilteringModal: React.FC<{
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}> = ({ show, setShow }) => {
  const { t } = useTranslation('components');

  const { filterAnomalyValidationType, setFilterAnomalyValidationType } = usePlots();

  return (
    <Modal
      title={
        <>
          <FontAwesomeIcon className="mr-2" icon={faFilter} />
          {t('modals.AnomalyFilteringModal.title')}
        </>
      }
      show={show}
      setShow={() => setShow(false)}
    >
      <select
        onChange={e => {
          const selectElement = e.target;
          const optionElement = selectElement.options[selectElement.selectedIndex];
          const value = Number(optionElement.value);

          if (value === 0) {
            setFilterAnomalyValidationType(undefined);
          } else {
            setFilterAnomalyValidationType(value as AnomalyValidationType);
          }
          setShow(false);
        }}
        value={filterAnomalyValidationType}
      >
        <option value={0}>{t('modals.AnomalyFilteringModal.options.none')}</option>
        <option value={AnomalyValidationType.NotValidated}>
          {t('modals.AnomalyFilteringModal.options.NotValidated')}
        </option>
        <option value={AnomalyValidationType.AnomalyCorrectlyMarked}>
          {t('modals.AnomalyFilteringModal.options.AnomalyCorrectlyMarked')}
        </option>
        <option value={AnomalyValidationType.AnomalyIncorrectlyMarked}>
          {t('modals.AnomalyFilteringModal.options.AnomalyIncorrectlyMarked')}
        </option>
      </select>
    </Modal>
  );
};

export default AnomalyFilteringModal;
