import { useState } from 'react';

import AnomalyFilteringModal from 'components/modals/AnomalyFilteringModal';
import GatewayHealthIndicatorModal from 'components/modals/GatewayHealthIndicatorModal';
import GatewaySensorsHealthIndicatorModal from 'components/modals/GatewaySensorsHealthIndicatorModal';
import GroupGatewaysHealthIndicatorModal from 'components/modals/GroupGatewaysHealthIndicatorModal';
import GroupHealthIndicatorModal from 'components/modals/GroupHealthIndicatorModal';
import SensorGatewaysModal from 'components/modals/SensorGatewaysModal';
import { SensorsHealthIndicatorModal } from 'components/modals/SensorsHealthIndicatorModal';
import PlotContext, { ContextProps, CreateToolboxSettingsProps } from 'utils/contexts/PlotContext';
import AnomalyValidationType from 'utils/enums/AnomalyValidationType';
import toolboxSettings from 'utils/plots/toolbox';

const PlotSession: React.FC<
  React.PropsWithChildren<
    Pick<
      ContextProps,
      | 'enableAnomalies'
      | 'enableForecasts'
      | 'onTransmissionClick'
      | 'onTimePeriodSelectClick'
      | 'onMarkAnomaliesClick'
      | 'timePeriod'
      | 'highlightPeriod'
    > & {
      sensorId?: string;
      groupId?: string;
      gatewayId?: string;
      sensorIds?: string[];
    }
  >
> = ({
  enableAnomalies,
  enableForecasts,
  onTransmissionClick,
  onTimePeriodSelectClick,
  onMarkAnomaliesClick,
  sensorId,
  groupId,
  gatewayId,
  sensorIds,
  children,
  timePeriod,
  highlightPeriod,
}) => {
  const [showAnomalyFilteringModal, setShowAnomalyFilteringModal] = useState(false);
  const [showAnomalies, setShowAnomalies] = useState(false);
  const [showForecasts, setShowForecasts] = useState(false);
  const [showSignalStrength, setShowSignalStrength] = useState(false);
  const [showGatewayHealth, setShowGatewayHealth] = useState(false);
  const [showGateways, setShowGateways] = useState(false);
  const [filterAnomalyValidationType, setFilterAnomalyValidationType] =
    useState<AnomalyValidationType>();

  const createToolboxSettings = ({ saveAsImageFilename, ...props }: CreateToolboxSettingsProps) =>
    toolboxSettings({
      saveAsImageFilename,
      showForecasts,
      timePeriod,
      highlightPeriod,
      onTimePeriodSelectClick,
      onShowForecastsClick: enableForecasts
        ? () => setShowForecasts(previousValue => !previousValue)
        : undefined,
      onShowSignalStrengthClick: () => setShowSignalStrength(previousValue => !previousValue),
      onShowGatewaysClick: sensorId
        ? () => setShowGateways(previousValue => !previousValue)
        : undefined,
      onMarkAnomaliesClick,
      onShowAnomalyFilteringClick: enableAnomalies
        ? () => setShowAnomalyFilteringModal(true)
        : undefined,
      ...props,
    });

  return (
    <PlotContext.Provider
      value={{
        enableAnomalies,
        enableForecasts,
        filterAnomalyValidationType,
        onMarkAnomaliesClick,
        onTimePeriodSelectClick,
        onTransmissionClick,
        setFilterAnomalyValidationType,
        setShowAnomalies,
        setShowAnomalyFilteringModal,
        setShowForecasts,
        setShowGatewayHealth,
        setShowGateways,
        setShowSignalStrength,
        showAnomalies,
        showForecasts,
        showGatewayHealth,
        showGateways,
        showSignalStrength,
        timePeriod,
        highlightPeriod,
        createToolboxSettings,
      }}
    >
      {children}

      {enableAnomalies && (
        <AnomalyFilteringModal
          show={showAnomalyFilteringModal}
          setShow={setShowAnomalyFilteringModal}
        />
      )}

      {sensorId && (
        <>
          <SensorsHealthIndicatorModal
            show={showSignalStrength}
            setShow={setShowSignalStrength}
            sensorIds={[sensorId]}
            timePeriod={timePeriod}
          />

          <SensorGatewaysModal show={showGateways} setShow={setShowGateways} sensorId={sensorId} />
        </>
      )}

      {sensorIds && (
        <>
          <SensorsHealthIndicatorModal
            show={showSignalStrength}
            setShow={setShowSignalStrength}
            sensorIds={sensorIds}
            timePeriod={timePeriod}
          />
        </>
      )}

      {groupId && (
        <>
          <GroupHealthIndicatorModal
            show={showSignalStrength}
            setShow={setShowSignalStrength}
            groupId={groupId}
            timePeriod={timePeriod}
          />

          <GroupGatewaysHealthIndicatorModal
            show={showGatewayHealth}
            setShow={setShowGatewayHealth}
            groupId={groupId}
          />
        </>
      )}

      {gatewayId && (
        <>
          <GatewaySensorsHealthIndicatorModal
            show={showSignalStrength}
            setShow={setShowSignalStrength}
            gatewayId={gatewayId}
            timePeriod={timePeriod}
          />

          <GatewayHealthIndicatorModal
            show={showGatewayHealth}
            setShow={setShowGatewayHealth}
            gatewayId={gatewayId}
          />
        </>
      )}
    </PlotContext.Provider>
  );
};

export default PlotSession;
