import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import api from 'utils/api';
import { floorToLatest5Minutes } from 'utils/date';
import { useSensorsBulk } from 'utils/hooks/data/sensors-bulk/useSensorsBulk';
import { parseTransmissionsMixed } from 'utils/sensor/transmissions';
import HookOptions from 'utils/types/HookOptions';
import { parseTransmission } from 'utils/types/Transmission';

export const useSensorsBulkTransmissions = (
  sensorIds: string[],
  {
    fromTimestamp = undefined,
    toTimestamp = undefined,
    limit = undefined,
    includeMetaColumns = false,
  }: {
    fromTimestamp?: Date;
    toTimestamp?: Date;
    limit?: number;
    includeMetaColumns?: boolean;
  } = {},
  { enableGet = true }: HookOptions = {},
) => {
  fromTimestamp = floorToLatest5Minutes(fromTimestamp!);
  toTimestamp = floorToLatest5Minutes(toTimestamp!);

  const queryKey = [
    'useSensorsBulkTransmissions',
    sensorIds,
    fromTimestamp,
    toTimestamp,
    limit,
    includeMetaColumns,
  ];

  const {
    data: transmissions,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.post('/sensors_bulk/transmissions', {
        sensor_ids: sensorIds,
        from_timestamp: fromTimestamp,
        to_timestamp: toTimestamp,
        limit,
        include_meta_columns: includeMetaColumns,
      }),
    select: (transmissionsRaw: any[]) => transmissionsRaw.map(parseTransmission),
    enabled: enableGet && sensorIds && sensorIds.length > 0 && !!fromTimestamp && !!toTimestamp,
  });

  const { sensors } = useSensorsBulk(sensorIds);
  const transmissionsParsed = useMemo(
    () =>
      parseTransmissionsMixed({
        transmissions,
        sensors: sensors || [],
        anomalies: [],
      }),
    [transmissions, sensors],
  );

  return {
    transmissions: transmissionsParsed,
    isPending: isPending || isLoading || isFetching,
  };
};
