import debounce from 'lodash/debounce';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';

export const DebouncedNumericInput: React.FC<{
  minValue: number;
  maxValue: number;
  defaultValue: number;
  suffix?: string;
  debouncedSetValueCallback: Dispatch<SetStateAction<number>>;
}> = ({ minValue, maxValue, defaultValue, suffix, debouncedSetValueCallback }) => {
  const [currentValue, setCurrentValue] = useState<number>(defaultValue);

  const setValueDebounced = debounce((value: number) => {
    !isNaN(value) ? debouncedSetValueCallback(value) : debouncedSetValueCallback(currentValue);
  }, 2000);

  const onValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parseInt(event.target.value, 10);
    setValueDebounced(parsedValue);
    setCurrentValue(parsedValue);
  };

  return (
    <div className="flex items-center">
      <input
        className="w-2/4 mr-2"
        type="number"
        inputMode="numeric"
        defaultValue={defaultValue}
        onChange={onValueChange}
        min={minValue}
        max={maxValue}
      />
      {suffix && suffix}
    </div>
  );
};
