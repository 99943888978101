import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseAlarm } from 'utils/types/Alarm';
import HookOptions from 'utils/types/HookOptions';

type Options = HookOptions & {
  seenOnly?: boolean;
  unseenOnly?: boolean;
  resolvedOnly?: boolean;
  unresolvedOnly?: boolean;
  fromTimestamp?: Date;
  toTimestamp?: Date;
};

export const useUserAlerts = (
  userId?: string,
  {
    enableGet = true,
    seenOnly,
    unseenOnly,
    resolvedOnly,
    unresolvedOnly,
    fromTimestamp,
    toTimestamp,
  }: Options = {},
) => {
  const queryKey = [
    'useUserAlerts',
    userId,
    seenOnly,
    unseenOnly,
    resolvedOnly,
    unresolvedOnly,
    fromTimestamp,
    toTimestamp,
  ];

  const {
    data: alerts,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/users/${userId}/alerts`, {
        params: {
          seen_only: seenOnly,
          unseen_only: unseenOnly,
          resolved_only: resolvedOnly,
          unresolved_only: unresolvedOnly,
          from_timestamp: fromTimestamp,
          to_timestamp: toTimestamp,
        },
      }),
    select: (alerts: any[]) => alerts.map(parseAlarm),
    enabled: !!userId && enableGet,
  });

  return {
    alerts,
    isPending: isPending || isLoading || isFetching,
  };
};
