import { useMutation } from '@tanstack/react-query';

import api from 'utils/api';

export const useSensorsBulkLoRaKeys = () => {
  const { mutateAsync: downloadLoRaKeysCSV } = useMutation({
    mutationFn: (sensorIds: string[]) =>
      api.post('/sensors_bulk/lora_keys/export', {
        sensor_ids: sensorIds,
      }),
    onSuccess: async res => {
      // Create a blob from the response and download it as a csv file
      const blob = new Blob([res], { type: 'text/csv;' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'export-sensor-lora-keys.csv';
      a.click();
    },
  });

  return {
    downloadLoRaKeysCSV,
  };
};
